import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import Files from "../../Config/Files";
import { FontFamily } from "../../Config/theme";
import CCard from "../../Components/Ccard";
import BaseColor from "../../Config/Color";
import { motion } from "framer-motion";
import { cardData } from "../../Config/staticData";
import CFooter from "../../Components/CFooter";
const HowWeWork = () => {
  const containerVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.6, ease: "easeInOut" },
    },
  };
  useEffect(() => {
    // Scroll to the top of the page
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const ThisArray = [
    {
      id: 1,
      title: "Research: Gathering Information from all around",
      desc1:
        "Webaartz values each business as unique and we spend time with you in understanding your working model. In this first phase, we appreciate your business goals and discuss your expectations about your web presence. With extensive research and analysis, we figure out the bigger picture of your market and competition for it on the web. Using all congregated information, we are set to prepare the plan for desired outcomes.",

      img: Files?.HowweworkArray.HowImg1,
    },
    {
      id: 2,
      title: "Plan: Effective strategies for favorable outcomes",
      desc1: `Using all assembled information and keeping your business goals in mind, we plan to plot innovative solutions for your brand to experience quick recognition among the masses. We sit with you to brainstorm the road map for in-budget and on-time solutions. By the end of this phase, we have with us - the guide to follow, the basics of the content and graphics to be used and the appropriate technologies to employ.`,

      img: Files?.HowweworkArray.HowImg2,
    },
    {
      id: 3,
      title: "Implement: Timely execution as per the plan",
      desc1: `We choose to implement our plans under a mechanized strategy for best results in limited time. Our aim is to create a healthy environment for your business to expand to multiple targeted markets. From deciding upon the right keywords to framing stunning informational content, eye catchy graphics and web designs to making the best use of technology for your website, we manage all fine details to improve engagement with your target audience that drives response and conversions.`,
      img: Files?.HowweworkArray.HowImg3,
    },
    {
      id: 4,
      title: "Test & Deliver: Making a successful launch",
      desc1: `After implementation, it is time to cross check the content, designs and functionality. We rigorously test the website to be mobile ready and cross browser compatibility with the latest browsers apart from ensuring all features as promised have been delivered. We test all database connections and forms to accurately record user inputs and seek your approval for the website before making it live. We finally check for broken links and the website is published for the public.`,

      img: Files?.HowweworkArray.HowImg4,
    },
    {
      id: 5,
      title: "Optimize: Steadily climbing up the Hill",
      desc1: `The real work starts for us as soon as your business is launched on the web. We keep a close eye on your brand and measure various statistics to know how it is performing on the web. We support and maintain your brand all through its lifetime. We also optimize it to improve lead generation. There are new competitions in the markets every day and we back you up with different strategies to make you Unstoppable!`,
      img: Files?.HowweworkArray.HowImg5,
    },
  ];

  return (
    <>
      <div style={{ paddingTop: "100px", backgroundColor: BaseColor.white }}>
        {/* <div>
          <CHeader />
        </div> */}
        <Grid container>
          <Grid item xs={12} sm={12} md={0} lg={1} xl={1} />
          <Grid item xs={12} sm={12} md={12} lg={10} xl={4}>
            <motion.div
              style={{ paddingTop: "30px", textAlign: "center" }}
              initial={{ opacity: 0.5, x: -150 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
            >
              <span
                style={{
                  flexDirection: "row",
                  justifyContent: "center",
                  fontFamily: FontFamily.ExtraBold,
                  fontSize: 46,
                }}
              >
                {" "}
                How it's work block
              </span>
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={12} md={0} lg={1} xl={4} />
        </Grid>
        <div>
          <Grid container>
            <Grid item xs={0} sm={0} md={0} lg={4} xl={4.5} />
            <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
              <motion.div
                style={{ paddingTop: "30px", textAlign: "center" }}
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                // whileHover={{ color: "red" }}
              >
                <span
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    fontFamily: FontFamily.Regular,
                    fontSize: 36,
                  }}
                >
                  {" "}
                  Our Working Process
                </span>
              </motion.div>
            </Grid>
            <Grid item xs={1} sm={1} md={0} lg={4} xl={2} />
          </Grid>

          <div style={{ paddingTop: "40px" }}>
            <Grid container>
              <Grid item xs={12} sm={12} md={4} lg={1} xl={1} />
              <Grid item xs={12} sm={12} md={6} lg={10} xl={10}>
                <Grid container>
                  {cardData.map((card, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={2.4}
                      xl={2.4}
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "20px 0px",
                      }}
                    >
                      <CCard
                        normalCard
                        title={card.title}
                        content={card.content}
                        dis={card.dis}
                        color={card.color}
                        icon={card.icon}
                      />{" "}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={0} lg={1} xl={1} />
            </Grid>
          </div>
          <Grid container>
            <Grid item xs={0} sm={0} md={0} lg={3} xl={3} />
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <motion.div
                style={{ paddingTop: "50px", textAlign: "center" }}
                initial={{ opacity: 0, y: 150 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                // whileHover={{ color: "red" }}
              >
                <span
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    fontFamily: FontFamily.Regular,
                    fontSize: 36,
                  }}
                >
                  {" "}
                  The Process - Described In Detail
                </span>
              </motion.div>
            </Grid>
            <Grid item xs={1} sm={1} md={0} lg={3} xl={3} />
          </Grid>

          <Grid container>
            <Grid item xs={0.5} sm={0.5} md={1} lg={1} xl={1} />
            <Grid item
              xs={11}
              sm={11}
              md={10}
              lg={10}
              xl={10}
              style={{
                color: BaseColor.blackColor,
              }}
            >
              <div>
                {ThisArray.map((item, index) => {
                  return (
                    <Grid
                      container
                      style={{
                        display: "flex",
                        flexDirection:
                          item?.id % 2 === 0 ? "row-reverse" : "row",
                        margin: "70px 0px 20px 0px",
                      }}
                      key={index}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={5.5}
                        xl={5.5}
                        style={{
                          display: "flex",
                        }}
                        // spacing={2}
                      >
                        <motion.div
                          style={{}}
                          whileHover={{ scale: 1.1 }}
                          className="animation-container"
                          variants={containerVariants}
                          initial="hidden"
                          animate="visible"
                        >
                          <img
                            src={item?.img}
                            alt=""
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "contain",
                            }}
                            // // borderRadius: 12,
                            // boxShadow:
                            //   '0px 0px 6px 0px rgba(196, 196, 196, 0.80)'
                          />
                        </motion.div>
                      </Grid>
                      <Grid item lg={1} xl={1}></Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={5.5}
                        xl={5.5}
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          style={{}}
                          whileHover={{ scale: 1.1 }}
                          className="animation-container"
                          variants={containerVariants}
                          initial="hidden"
                          animate="visible"
                        >
                          <div style={{ padding: "20px 0px" }}>
                            <span
                              style={{
                                fontFamily: FontFamily.Bold,
                                fontSize: 22,
                                lineHeight: "210%",
                              }}
                            >
                              {index + 1 + ". "} {item?.title}
                            </span>
                          </div>
                          <div style={{ padding: "5px 0px" }}>
                            <span
                              style={{
                                fontFamily: FontFamily.Regular,
                                lineHeight: "210%",
                                fontSize: 16,
                              }}
                            >
                              {item?.desc1}
                            </span>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  );
                })}
              </div>
            </Grid>
            <Grid item xs={0} sm={0} md={1} lg={1} xl={1} />
          </Grid>
        </div>
      </div>
      {/* <Stayinhteloop /> */}

      <div>
        <CFooter />
      </div>
    </>
  );
};

export default HowWeWork;
