import React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { FontFamily } from "../../Config/theme";
import { useTheme ,useMediaQuery } from '@mui/material'
import Color from '../../Config/Color'
const BlogsCard = ({mayItem}) => {

  const theme = useTheme();
  const  isMatch = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
     <Card sx={{ borderRadius:"10px",width: "85%" ,isMatch :{
      width:"90%"
     } , isMatch1 :{
      width:
      "100%"
     } }} >
      <CardActionArea>
        <CardMedia
          component="img"
          height= {isMatch ? "95%" : "90%"}
          image={mayItem.img}
          alt="green iguana"
          sx={{objectFit:'contain' ,alignItems:'center' ,display:'flex' ,justifyContent:'center'}}
        />
        <CardContent>
          <Typography gutterBottom sx={{fontFamily:FontFamily.ExtraBold}} component="div">
          {mayItem.name}
          </Typography>
          <Typography sx={{fontFamily:FontFamily.Medium,color:Color.grey,paddingTop:'20px' }} >
          {mayItem.desc}
          </Typography>
          <Typography sx={{display:"flex",marginTop:"40px"}} >
          <img
              alt="Remy Sharp"
              src={mayItem.user}
              style={{  width:'20%' ,objectFit:'contain'}}
            />
          <div style={{margin:15}}>
          <Typography sx={{fontFamily:FontFamily.ExtraBold,margin:"5px 0px 0px 10px"}}>Name here</Typography>
          <Typography sx={{fontFamily:FontFamily.Light,margin:"3px 0px 0px 10px",position:"absolute"}}>19/10/23</Typography>
          </div></Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    </>
  );
};
export default BlogsCard;