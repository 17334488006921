// import myLogo from '../Assets/Svg/logoWithioutBG.svg'
import camera from "../Assets/Svg/Camera.svg";
import startup from "../Assets/Svg/startup1.svg";
import logo from "../Assets/Svg/logoHeader.svg";
import Bg from "../Assets/Svg/Rectangle126.svg";
import Vihil from "../Assets/Svg/Vihil.svg";
import codeSlash from "../Assets/Svg/codeslash.svg";
import mobile from "../Assets/Svg/materialuiPhone.svg";
import Bulb from "../Assets/Svg/Bulb.svg";
import computer from "../Assets/Svg/computer.svg";
import blackPhone from "../Assets/Svg/3d-black-phone.svg";
import headphone from "../Assets/Svg/headphone.svg";
import eplise3 from "../Assets/Svg/Ellipse3.svg";
import ball from "../Assets/Svg/orangeball.svg";

const Files = {
  logo: {
    logo: require("../Assets/Logo/Vihil_logo_bg.png"),
    logoWithoutBg: require("../Assets/Logo/Vihil_logo_without_bg.png"),
  },
  images: {
    aboutUsImg: require("../Assets/Images/aboutUsImg.png"),
    privacyPolicyImg: require("../Assets/Images/privacyPolicyImg.png"),
    termsCondition: require("../Assets/Images/terms&conditionImf.png"),
    avtar1: require("../Assets/Images/Avatar1.png"),
    avtar2: require("../Assets/Images/Avatar2.png"),
    avtar3: require("../Assets/Images/Avatar3.png"),
    bulb: require("../Assets/Images/lightbulb.png"),
    bell: require("../Assets/Images/bell.png"),
    vector3: require("../Assets/Images/Vector3.png"),
    vector4: require("../Assets/Images/Vector4.png"),
    user: require("../Assets/Images/Ellipse 7.png"),
  },
  servicesImages: {
    servicesImg1: require("../Assets/Images/services1.png"),
    servicesImg2: require("../Assets/Images/services2.png"),
    servicesImg3: require("../Assets/Images/services3.png"),
    servicesImg4: require("../Assets/Images/services4.png"),
    servicesImg5: require("../Assets/Images/services5.png"),
    servicesImg6: require("../Assets/Images/services6.png"),
  },
  HowweworkArray: {
    HowImg1: require("../Assets/Images/how1.png"),
    HowImg2: require("../Assets/Images/how2.avif"),
    HowImg3: require("../Assets/Images/how3.webp"),
    HowImg4: require("../Assets/Images/how4.png"),
    HowImg5: require("../Assets/Images/how55.jpg"),
  },
  whoWeAreImages: {
    User1: require("../Assets/Images/bharat.jpg"),
    User2: require("../Assets/Images/manish.jpg"),
    User3: require("../Assets/Images/jaydeep.png"),
    User4: require("../Assets/Images/janvi.jpg"),
    User5: require("../Assets/Images/dhaval.jpg"),
    User6: require("../Assets/Images/mihir.jpg"),
    User7: require("../Assets/Images/dip.jpg"),
    User8: require("../Assets/Images/kinjal.png"),
    User9: require("../Assets/Images/jaydeep.png"),
    User10: require("../Assets/Images/nishil.jpg"),
    User11: require("../Assets/Images/niket.jpg"),
  },
  lottie: {
    loader: require("../Assets/lottie/lineLoader.json"),
    pageLoader: require("../Assets/lottie/pageLoader.json"),
    error404: require("../Assets/lottie/Error404.json"),
    comingsoon: require("../Assets/lottie/comingSoon.json"),
    // comingsoon1: require('../Assets/lottie/Animation.json'),
  },
  BlogsImages: {
    BlogsImage1: require("../Assets/Images/blogI1.png"),
    BlogsImage2: require("../Assets/Images/blogI2.png"),
    BlogsImage3: require("../Assets/Images/blogI3.png"),
    BlogsImage4: require("../Assets/Images/blogI4.png"),
    BlogsImage5: require("../Assets/Images/blogI5.png"),
    BlogsImage6: require("../Assets/Images/blogI6.png"),
  },
  BlogsUsersI: {
    BlogsUsersI1: require("../Assets/Images/bloguser1.png"),
    BlogsUsersI2: require("../Assets/Images/bloguser2.png"),
    BlogsUsersI3: require("../Assets/Images/bloguser3.png"),
    BlogsUsersI4: require("../Assets/Images/bloguser4.png"),
    BlogsUsersI5: require("../Assets/Images/bloguser5.png"),
    BlogsUsersI6: require("../Assets/Images/bloguser6.png"),
  },
  svgIcons: {
    // appLogo: logo
    camera: camera,
    startUp: startup,
    logo: logo,
    Vihil: Vihil,
    codeSlash: codeSlash,
    mobileMUI: mobile,
    blackPhone: blackPhone,
    computer: computer,
    bulb: Bulb,
    headphone: headphone,
    eplise3: eplise3,
    ball: ball,
    Bg: Bg,
  },
  BlogsImages: {
    BlogsImage1: require("../Assets/Images/blogI1.png"),
    BlogsImage2: require("../Assets/Images/blogI2.png"),
    BlogsImage3: require("../Assets/Images/blogI3.png"),
    BlogsImage4: require("../Assets/Images/blogI4.png"),
    BlogsImage5: require("../Assets/Images/blogI5.png"),
    BlogsImage6: require("../Assets/Images/blogI6.png"),
  },
  BlogsUsersI: {
    BlogsUsersI1: require("../Assets/Images/bloguser1.png"),
    BlogsUsersI2: require("../Assets/Images/bloguser2.png"),
    BlogsUsersI3: require("../Assets/Images/bloguser3.png"),
    BlogsUsersI4: require("../Assets/Images/bloguser4.png"),
    BlogsUsersI5: require("../Assets/Images/bloguser5.png"),
    BlogsUsersI6: require("../Assets/Images/bloguser6.png"),
  },
  HowWeWorkcardI: {
    CardImg1: require("../Assets/Images/coputer1.jpg"),
    CardImg2: require("../Assets/Images/coputer2.jpg"),
    CardImg3: require("../Assets/Images/coputer3.webp"),
  },
  HomeCarouseI: {
    CarouselImg1: require("../Assets/Images/HomeCarouselclock.png"),
    CarouselImg2: require("../Assets/Images/HomeCarouselVRArray.png"),
    CarouselImg3: require("../Assets/Images/HomeCarouselLineChart.png"),
  },

  Homepagepng: {
    computer: require("../Assets/Images/computer.png"),
    blub2: require("../Assets/Images/Bulb.png"),
    headphone: require("../Assets/Images/Rectangle.png"),
    phone: require("../Assets/Images/black-phone.png"),
    Ellipse: require("../Assets/Images/Ellipse54.png"),
    Ellipse1: require("../Assets/Images/Ellipse1.png"),
    vector: require("../Assets/Images/Vector 4.png"),
    vector3: require("../Assets/Images/Vector 3.png"),
    vector1: require("../Assets/Images/Vector 1.png"),
    vector2: require("../Assets/Images/Vector 1.png"),
  },

  howweworkpng: {
    firstImg: require("../Assets/Images/teaching.png"),
    secondImg: require("../Assets/Images/learning.png"),
    thridImg: require("../Assets/Images/markating.png"),
  },
  arrayImage: {
    first: require("../Assets/Images/digital-library.jpg"),
    second: require("../Assets/Images/laptopgirl.png"),
    thrid: require("../Assets/Images/pencil.jpg"),
  },
  avatarImage: {
    first: require("../Assets/Images/image_1.jpg"),
    seacond: require("../Assets/Images/document _1.jpg"),
    thirad: require("../Assets/Images/images3_1.jpg"),
  },
  whatwedoI: {
    whatwedoI1: require("../Assets/Images/whatwedo1.png"),
    whatwedoI2: require("../Assets/Images/whatwedo2.png"),
    whatwedoI3: require("../Assets/Images/whatwedo3.png"),
    whatwedoI4: require("../Assets/Images/whatwedo4.png"),
    whatwedoI5: require("../Assets/Images/whatwedo5.png"),
    whatwedoI6: require("../Assets/Images/whatwedo6.png"),
    whatwedoI7: require("../Assets/Images/whatwedo7.png"),
    whatwedoI8: require("../Assets/Images/whatwedo8.png"),
  },
  whatwedoI2: {
    whatwedoI21: require("../Assets/Images/what1.png"),
    whatwedoI22: require("../Assets/Images/what2.png"),
    whatwedoI23: require("../Assets/Images/what3.png"),
    whatwedoI24: require("../Assets/Images/what4.png"),
  },
  technologyIcon :{
     react:require("../Assets/Images/React-removebg-preview (1).png"),
     android:require("../Assets/Images/Android_logo_(2014-2019).png"),
     ios:require("../Assets/Images/apple-logo-png-dallas-shootings-don-add-are-speech-zones-used-4.png"),
     nextjs:require("../Assets/Images/nextjs-icon-512x512-11yvtwzn.png"),
     angular :require("../Assets/Images/angular.png"),
     shopyfy:require("../Assets/Images/images__3_-removebg-preview.png"),
     nodejs:require("../Assets/Images/node-js-icon-454x512-nztofx17.png"),
     wordPress : require("../Assets/Images/images__1_-removebg-preview.png"),
     html: require("../Assets/Images/1532556.png"),
     javascript:require("../Assets/Images/png-clipart-javascript-logo-computer-icons-vue-js-angle-text-thumbnail-removebg-preview.png"),
     firebase: require("../Assets/Images/images__2_-removebg-preview.png"),
     aws:require("../Assets/Images/aws.png"),
     vuejs:require("../Assets/Images/png-transparent-vue-js-hd-logo-thumbnail-removebg-preview.png"),
     phpweb:require("../Assets/Images/php.png"),
     python:require("../Assets/Images/python.png"),
     flutter:require("../Assets/Images/Flutter.png"),
     ionic:require("../Assets/Images/ionic.png"),
     native:require("../Assets/Images/NativeScript_Logo.png"),
  }
};

export default Files;
