import { makeStyles } from "@mui/styles";
import BaseColor from "../../Config/Color";
import { FontFamily } from "../../Config/theme";

const useStyles = makeStyles({
  titleStyle: {
    color: BaseColor.white,
    fontSize: 18,
    fontFamily: FontFamily.Regular,
    transition: 'text-shadow 0.3s ease', // Smooth transition for shadow effect
    '&:hover': {
      textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Add shadow on hover
    },
  },
});

export default useStyles;
