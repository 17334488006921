import { Grid, useTheme, useMediaQuery } from "@mui/material";
import { React } from "react";
import useStyles from "./styles";
import Files from "../../Config/Files";
import BaseColor from "../../Config/Color";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { FontFamily } from "../../Config/theme";
import { motion } from "framer-motion";
const Main = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const big = useMediaQuery(theme.breakpoints.between("1360", "1400"));
  const handleClick = () => {
    window.location.href = "mailto:vihil3010@gmail.com";
  };
  return (
    <>
      <Grid container style={{ marginBottom: 20 }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.inputsBackground}
          sx={{ height: sm ? "500px" : "none" }}
        >
          {sm ? null : (
            <motion.div>
              <motion.div>
                <motion.img
                  initial={{ opacity: 1, scale: 1, x: 20, y: 0 }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                    x: [10, 20, 10],
                    y: [0, 0, 0],
                    transition: {
                      duration: 3,
                      ease: "linear",
                      repeat: 100,
                      repeatType: "reverse",
                    },
                  }}
                  transition={{
                    repeat: 100,
                    repeatType: "reverse",
                    duration: 1000,
                    loop: Infinity,
                  }}
                  className={classes.computer}
                  src={Files?.svgIcons?.computer}
                  alt=""
                />
              </motion.div>
              <motion.div>
                <motion.img
                  initial={{ opacity: 1, scale: 1, x: 20, y: 0 }}
                  animate={{
                    opacity: [0, 1],
                    scale: 1,
                    x: [10, 20, 10],
                    y: [0, 0, 0],
                    transition: {
                      ease: "linear",
                      repeat: 100,
                      repeatType: "reverse",
                      delay: 1,
                      duration: 1,
                    },
                  }}
                  transition={{
                    repeat: 100,
                    repeatType: "reverse",
                    duration: 1000,
                    loop: Infinity,
                  }}
                  className={classes.eplisse}
                  src={Files?.Homepagepng.Ellipse}
                  alt=""
                />
              </motion.div>
            </motion.div>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "70vh",
              position: "relative",
              justifyContent: "flex-start",
            }}
          >
            {sm || isMatch ? null : (
              <img
                alt=""
                src={Files?.svgIcons?.Vihil}
                style={{
                  objectFit: "contain",
                  marginLeft: isMatch
                    ? "0%"
                    : //  isLargeScreen ? "20%" :
                      "17%",
                  width: "40%",
                  marginBottom: 40,
                  opacity: 0.6,
                }}
              />
            )}
            <div
              style={{
                position: "absolute",
                width: "100%",
                top: big ? 10 : 30,
              }}
            >
              <p
                style={{
                  fontSize: isMatch
                    ? 28
                    : // :
                    // isLargeScreen ? 80
                    40 && big
                    ? 40
                    : 40,
                  fontWeight: "bold",
                  color: BaseColor.white,
                  textAlign: "center",
                  fontFamily: FontFamily.ExtraBold,
                }}
              >
                Advanced software, advanced research
                <br /> for{" "}
                <span
                  style={{
                    fontSize: isMatch
                      ? 28
                      : //: isLargeScreen ? 80
                      40 && big
                      ? 40
                      : 40,
                    fontWeight: "bold",
                    color: BaseColor.blueBorder,
                  }}
                >
                  SIMPLE
                </span>{" "}
                life
              </p>
              {sm ? null : (
                <motion.div>
                  <motion.img
                    nitial={{ opacity: 1, scale: 1, x: 20, y: 0 }}
                    animate={{
                      opacity: [0.5, 1],
                      scale: 1,
                      x: [10, 20, 10],
                      y: [0, 0, 0],
                      transition: {
                        duration: 3,
                        ease: "linear",
                        repeat: 100,
                        repeatType: "reverse",
                      },
                    }}
                    transition={{
                      repeat: 100,
                      repeatType: "reverse",
                      duration: 1000,
                      loop: Infinity,
                    }}
                    className={classes.bulb}
                    src={Files?.svgIcons?.bulb}
                    alt=""
                  />
                </motion.div>
              )}

              <p
                style={{
                  fontSize: isMatch
                    ? 18
                    : // isLargeScreen ? 36 :
                    20 && big
                    ? 22
                    : 20,
                  fontWeight: "normal",
                  color: BaseColor.white,
                  textAlign: "center",
                  fontFamily: FontFamily.SemiBold,
                  paddingTop: big ? 20 : 30,
                }}
              >
                We harness the latest technologies to develop cutting-edge
                <br /> digital solutions for modern business requirements
              </p>
              <div
                style={{
                  width: isMatch ? "80%" : "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: isMatch ? "5px" : "100px" && big ? 100 : "100px",
                  margin: isMatch ? "10%" : "",
                }}
              >
                <div className={classes.contactUsBtn}>
                  <span className={classes.conatctUsText}>
                    {/* Contact us */}
                    <a
                      href="mailto:vihil3010@gmail.com"
                      className={classes.conatctUsText}
                    >
                      Contact us
                    </a>
                  </span>
                  <div className={classes.arrowStyle}>
                    <HiOutlineArrowNarrowRight
                      size={20}
                      color={BaseColor.blackColor}
                      className={classes.arrow}
                      // onClick={() => navigate("/contactus")}
                      onClick={handleClick}
                    />
                  </div>
                </div>
              </div>
              {sm || isMatch ? null : (
                <img
                  style={{
                    position: "absolute",
                    bottom: big ? "11.5%" : "3.5%",
                    // isLargeScreen ? "-16%" : "8.5%",
                    height: big ? "180px" : "230px",
                    // isLargeScreen ? "350px" : "280px",
                  }}
                  src={Files?.Homepagepng.vector}
                  alt=""
                />
              )}
              {/* {sm || isMatch ? null : (
                <div
                  style={{
                    color: BaseColor.white,
                    width: isMatch ? "30%" : "25%",
                    paddingTop: isMatch ? 5 : 15,
                    margin: "auto",
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: isMatch ? "10%" : "5%" && big ? 0 : "5%",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: isMatch ? "10px" : "15px",
                      paddingLeft: big ? "30px" : "none",
                    }}
                  >
                    <div
                      style={{
                        fontSize: isMatch
                          ? 16
                          : // : isLargeScreen
                          // ? 40
                          isHighDpiScreen
                          ? 80
                          : 26,
                        fontWeight: "bold",
                        fontFamily: FontFamily.Regular,
                      }}
                    >
                      06+
                    </div>
                    <div
                      style={{
                        fontFamily: FontFamily.Light,
                        fontSize: big ? 14 : "none",
                      }}
                    >
                      Businesses Trust <br />
                      Us.
                    </div>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: isMatch ? "10px" : "15px",
                      paddingRight: big ? "20px" : "60px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: isMatch
                          ? 16
                          : //  isLargeScreen ? 40 :
                            26,
                        fontWeight: "bold",
                        fontFamily: FontFamily.Regular,
                      }}
                    >
                      11+
                    </div>
                    <div
                      style={{
                        fontFamily: FontFamily.Light,
                        fontSize: big ? 14 : "none",
                      }}
                    >
                      Happy customers <br />
                      and clients.
                    </div>
                  </div>
                </div>
              )} */}
              {sm ? null : (
                <motion.div>
                  <motion.img
                    initial={{ opacity: 1, scale: 1, x: 20, y: 0 }}
                    animate={{
                      opacity: 1,
                      scale: 1,
                      x: [10, 20, 10],
                      y: [0, 0, 0],
                      transition: {
                        duration: 3,
                        ease: "linear",
                        repeat: 100,
                        repeatType: "reverse",
                      },
                    }}
                    transition={{
                      repeat: 100,
                      repeatType: "reverse",
                      duration: 1000,
                      loop: Infinity,
                    }}
                    style={{
                      transform: "translate(-50%, -100%)",
                    }}
                    className={classes.headphone}
                    src={Files?.svgIcons?.headphone}
                    alt=""
                  />
                  <motion.img
                    iinitial={{ opacity: 1, scale: 1, x: 20, y: 0 }}
                    animate={{
                      opacity: [0, 1],
                      scale: 1,
                      x: [10, 20, 10],
                      y: [0, 0, 0],
                      transition: {
                        duration: 3,
                        ease: "linear",
                        repeat: 100,
                        repeatType: "reverse",
                        delay: 1,
                      },
                    }}
                    transition={{
                      repeat: 100,
                      repeatType: "reverse",
                      duration: 1000,
                      loop: Infinity,
                    }}
                    className={classes.eplisse1}
                    src={Files?.Homepagepng.Ellipse1}
                    alt=""
                  />
                </motion.div>
              )}

              {/* {sm ? null : (
                <div
                  style={{
                    paddingTop: isMatch ? 0 : 100,
                    paddingBottom: isMatch ? -50 : 0,
                  }}
                >
                  <motion.div
                    initial={{ opacity: 1, scale: 1, x: 20, y: 0 }}
                    animate={{
                      opacity: 1,
                      scale: 1,
                      x: [10, 20, 10],
                      y: [0, 0, 0],
                      transition: {
                        duration: 3,
                        ease: "linear",
                        repeat: 100,
                        repeatType: "reverse",
                      },
                    }}
                    transition={{
                      repeat: 100,
                      repeatType: "reverse",
                      duration: 1000,
                      loop: Infinity,
                    }}
                    style={{
                      transform: "translate(-50%, -100%)",
                    }}
                    className={classes.boxMainContainer}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: 10,
                      }}
                    >
                      <img
                        alt=""
                        src={Files?.images?.avtar2}
                        className={classes.userImg}
                      />
                      <span className={classes.username}>Janvi shah</span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        paddingLeft: 10,
                      }}
                    >
                      <Rating
                        name="simple-controlled"
                        value={5}
                        readOnly
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                        style={{ margin: big ? "5px 0px" : "15px 0px" }}
                        className={classes.customRating}
                        size={
                          isMatch
                            ? "large"
                            : "medium" && big
                            ? "small"
                            : "medium"
                        }
                      />
                      <span
                        style={{
                          color: BaseColor.white,
                          fontSize: 12,
                          fontWeight: "500",
                          marginLeft: 10,
                        }}
                      >
                        5.0
                        {value.toFixed(5)}
                      </span>
                    </div>
                    <span className={classes.textuser}>
                      Great Company and <br />{" "}
                    </span>
                    <span className={classes.textuser}>Premium services</span>
                    <br />
                    {isMatch ? (
                      <div className={classes.textwrap}>
                        The articles are insightful and informative, and the
                        design ....
                      </div>
                    ) : (
                      <div className={classes.textwrap}>
                        The articles are insightful and informative, and the
                        design of the website is sleek and modern.......
                      </div>
                    )}
                  </motion.div>
                </div>
              )} */}

              {/* {sm ? null : <div className={classes.popularTechBox}></div>} */}

              {/* {sm ? null : (
                <motion.div
                  initial={{ opacity: 1, scale: 1, x: 20, y: 0 }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                    x: [10, 20, 10],
                    y: [0, 0, 0],
                    transition: {
                      duration: 3,
                      ease: "linear",
                      repeat: 100,
                      repeatType: "reverse",
                    },
                  }}
                  transition={{
                    repeat: 100,
                    repeatType: "reverse",
                    duration: 1000,
                    loop: Infinity,
                  }}
                  style={{
                    transform: "translate(-50%, -100%)",
                  }}
                  className={classes.mainContainer}
                >
                  <div className={classes.subContainer}>
                    {sm || isMatch ? null : (
                      <img
                        className={classes.vector1}
                        src={Files?.Homepagepng.vector1}
                        alt=""
                      />
                    )}
                    <div className={classes.circle}>
                      <img alt="" src={Files?.svgIcons?.codeSlash} />
                    </div>
                    <div>
                      <span className={classes.maintext}>Web Development</span>{" "}
                      <br />
                      <div style={{ marginTop: "11px", marginBottom: "5px" }}>
                        <span className={classes.anothertext}>personal</span>
                        <span className={classes.anothertext1}>coding</span>
                      </div>
                    </div>
                  </div>
                </motion.div>
              )}
              {sm ? null : (
                <motion.div
                  initial={{ opacity: 1, scale: 1, x: 0, y: 0 }}
                  animate={{
                    opacity: 1,
                    scale: 1,
                    x: [0, 10, 0],
                    y: [0, 0, 0],
                    transition: {
                      duration: 3,
                      ease: "linear",
                      repeat: 100,
                      repeatType: "reverse",
                    },
                  }}
                  transition={{
                    repeat: 100,
                    repeatType: "reverse",
                    duration: 1000,
                    loop: Infinity,
                  }}
                  style={{
                    transform: "translate(-50%, -100%)",
                  }}
                  className={classes.mainContainer2}
                >
                  <div className={classes.subContainer}>
                    <div className={classes.circle2}>
                      <img alt="" src={Files?.svgIcons?.mobileMUI} />
                    </div>

                    <div>
                      <span className={classes.maintext}>
                        {"App Development"}
                      </span>{" "}
                      <br />
                      <div style={{ marginTop: "11px" }}>
                        <span className={classes.anothertext}>Work</span>
                        <span className={classes.anothertext3}>Business</span>
                        <span className={classes.anothertext4}>Custom</span>
                      </div>
                    </div>
                  </div>
                </motion.div>
              )} */}
            </div>
          </div>

          <motion.div style={{ display: "flex", justifyContent: "flex-end" }}>
            {sm ? null : (
              <motion.img
                initial={{ opacity: 1, scale: 1, x: 20, y: 0 }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  x: [10, 20, 10],
                  y: [0, 0, 0],
                  transition: {
                    duration: 3,
                    ease: "linear",
                    repeat: 100,
                    repeatType: "reverse",
                  },
                }}
                transition={{
                  repeat: 100,
                  repeatType: "reverse",
                  duration: 1000,
                  loop: Infinity,
                }}
                style={{
                  transform: "translate(-50%, -100%)",
                }}
                className={classes.phoneimg}
                src={Files?.svgIcons?.blackPhone}
                alt=""
              />
            )}
            {sm ? null : (
              <motion.img
                initial={{ opacity: 1, scale: 1, x: 20, y: 0 }}
                animate={{
                  opacity: [0, 1],
                  scale: 1,
                  x: [10, 20, 10],
                  y: [0, 0, 0],
                  transition: {
                    ease: "linear",
                    repeat: 100,
                    repeatType: "reverse",
                    delay: 1, // Delay the start of the animation
                    duration: 1, // Duration of the fade-in
                  },
                }}
                transition={{
                  repeat: 100,
                  repeatType: "reverse",
                  duration: 1000,
                  loop: Infinity,
                }}
                className={classes.phoneshadow}
                src={Files?.Homepagepng.Ellipse}
                alt=""
              />
            )}
          </motion.div>

          {sm || isMatch ? null : (
            <img
              style={{
                position: "absolute",
                bottom: big ? "-50px" : "-50px",
                height: big ? "120px" : "138px",
                right: isMatch ? "10%" : "15%",
              }}
              src={Files?.Homepagepng.vector3}
              alt=""
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Main;
