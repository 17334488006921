const BaseColor = {
  primary: '#070D18',
  secondory: '#050B17',
  blue:'#0096FF',
  orannge: '#FFA833',
  skyBlue:'#87CEEB',
  green: '#40E0D0',
  darkPink: 'var(--Pink, #FD5B71)',
  headigTextColor: '#101828',
  gray: '#667085',
  backgroundCard: 'var(--gray-50, #F9FAFB)',
  gray1: 'var(--gray-500, #667085)',
  disablePrimary: '#C3ACD0',
  offWhite: '#F7EFE5',
  transparentBlue: '#1E232E',
  textGrey:'#A6A6A6',
  background: `rgba(196, 196, 196, 0.19)`,
  darkGray:`rgba(255, 255, 255, 0.15)`,
  whiteBlur: `rgba(255, 255, 255, 0.36)`,
  whiteColor: '#FFFBF5',
  grayColor: '#F1F4F5',
  grayBorder: '#CBCBCB',
  blueBorder: '#2EA1DA',
  skyBlues: '#42BDEC',
  red: '#FF1010',
  errorRed: '#FF0000',
  redBorder: '#FF0404',
  textGray: '#9B9B9B',
  textColor: '#2D2D2D',
  transparent: '#ffffff00',
  white10: '#ffffff10',
  white20: '#ffffff20',
  white30: '#ffffff30',
  white40: '#ffffff40',
  white50: '#ffffff50',
  white60: '#ffffff60',
  white70: '#ffffff70',
  white80: '#ffffff80',
  white90: '#ffffff90',
  white: '#ffffff',
  black10: '#00000010',
  black20: '#00000020',
  black30: '#00000030',
  black40: '#00000040',
  black50: '#00000050',
  black60: '#00000060',
  black70: '#00000070',
  black80: '#00000080',
  black90: '#00000090',
  blackColor: '#000000',
  lightPurple: '#C3ACD0',
  activeStatus: '#00C100',
  extraLightPurple: '#FCF6FF',
  darkpurper: '#9B51E0',
  lightgrey :'#828282',
  bggrey:'#F2F2F2',
  pink :'#FD5B71',
  bgpink : '#FFEFF1',
  purple :'#9B51E0',
  bgpurple :'#F5EEFC',
  yellow :'#FFB800',
  bgyellow :'#F4EACF',
  carret :'#FD5B71',
  grey:'#777777',
  darkred:'#666',
  vdarkpurper:'#6E00FA',
  darkblue:'#0073C6',
  mediumGrey:'#737373',
  vdarkGray:'#242424',
  grey10:'#646464',
  white100:'#FFFEFE',
  grey44:'#707070',
  smokewhite:'#FFF2F2',
  yellowWhite:'#FDFBDA',
  bluewhite:'#E2F3FF',
  pinkwhite:'#FFE7FB',
  orangewhite:'#F6EEE7',
  redwhite:'#F1F1F1',
  purplewhite:'#F1E8FF',
  greenwhite:'#E2FFF1',
  verydarkblue:'#183643',geryred:'#F8F8F8',
  placeholder:'#CECECE',
  palceholder1:'#4F4F4F',
  palceholder2:'#B4B4B4',
  borderColor: '#CCCCCC',
  
}

export default BaseColor
