import { Divider, useTheme, useMediaQuery } from "@mui/material";
import { React, useEffect } from "react";
import CFooter from "../../Components/CFooter";
import Slider2 from "../../Components/FirstHomeSlider/First2";
import Main from "../../Components/Cmain/Main";
import Vision from "../../Components/Cvision/Vision";
import Whatwe from "../../Components/Cwhatwe/Whatwe";
import Statastics from "../../Components/Cstatastic/Statastics";

const Home = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    // Scroll to the top of the page
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingTop: 80,
      }}
    >
      <Main />
      <Vision />
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "2%",
          paddingBottom: "3.5%",
        }}
      >
        <div style={{ width: isMatch ? "140%" : "95%", overflow: "hidden" }}>
          <Slider2 />
        </div>
      </div>
      <Whatwe />
      <Statastics />

      {/* <VarticalSlider/> */}
      <CFooter />
    </div>
  );
};

export default Home;
