import { Grid } from "@mui/material";
import { React } from "react";
import BaseColor from "../../Config/Color";
import { FontFamily } from "../../Config/theme";
import _ from "lodash";

const Statastics = () => {
  const data = [
    { name: "60", content: "Happy Clients" },
    { name: "60", content: "Completed Projects" },
    { name: "20+", content: "Experienced Staff" },
    { name: "4.8", content: "Ratings" },
    // Add more objects as needed
  ];
  // const [data, setData] = useState([]);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  // const fetchData = async () => {
  //   const URL = BaseSetting.apiUrl
  //   try {
  //     const response = await axios.get(
  //       URL + "common?page=1&limit=50&placeOfContent=statistics"
  //     );
  //     const result = response?.data;
  //     if (!_.isEmpty(result?.results) && _.isArray(result?.results)) {
  //       setData(result?.results);
  //     } else {
  //       setData([]);
  //     }
  //   } catch (error) {
  //     setData([]);
  //   }
  // };

  return (
    <div>
      <Grid
        container
        sx={{
          backgroundColor: BaseColor.blackColor,
          color: BaseColor.white,
          padding: "10px 10px",
        }}
      >
        {!_.isEmpty(data) &&
          _.isArray(data) &&
          data?.map((item, index) => (
            <Grid
              key={index}
              sx={{ textAlign: "center", padding: "40px 20px" }}
              item
              xs={12}
              sm={6}
              md={6}
              lg={3}
              xl={3}
            >
              <div style={{ fontSize: "45px", fontFamily: FontFamily.Regular }}>
                {item?.name}
              </div>
              <div style={{ fontFamily: FontFamily.Regular }}>
                {item?.content}
              </div>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default Statastics;
