import { combineReducers } from "redux";
import authReducer from "./Auth/reducer";
import commonReducer from "./Common/reducer";
import languageReducer from "./Language/reducer";

const rootReducer = combineReducers({
  authReducer,
  commonReducer,
  languageReducer,
});

export default rootReducer;
