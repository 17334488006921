/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { FontFamily } from "../../Config/theme";
import Color from "../../Config/Color";

const cardStyle = {
  width: "70%",
  borderRadius: "8px",
  marginBottom: 50,
  overflow: "hidden",
  transition: "transform 0.3s, box-shadow 0.3s",
  cursor: "pointer",
  boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.2)", // Initial shadow
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: "0px -8px 16px rgba(0, 0, 0, 0.2)", // Shadow on hover (top side)
  },
  padding: "20px 10px 10px 10px",
};

const imageStyle = {
  width: "100%",
  height: "200px", // Adjusted height to make the image smaller
  borderRadius: "8px 8px 0 0",
  objectFit: "contain",

  transition: "transform 0.3s",
  "&:hover": {
    transform: "scale(1.1)",
  },
};

const titleStyle = {
  fontFamily: FontFamily.Light,
  fontSize: "1.2rem", // Adjusted font size to make the title smaller
  textAlign: "center",

  color: Color.darkpurper,
};

const positionStyle = {
  color: Color.blackColor,
  fontSize: "1rem", // Adjusted font size to make the position smaller
  textAlign: "center",
};

const descriptionStyle = {
  color: Color.darkred,
  fontFamily: FontFamily.Medium,
  margin: "8px 0",
  fontSize: "0.9rem", // Adjusted font size to make the description smaller
  textAlign: "center",
  padding: "0 15px",
};

export const CardSlider = ({ image, title, description, position }) => {
  const isSmallScreen = window.innerWidth <= 600; // Adjust the breakpoint as needed

  const responsiveCardStyle = {
    ...cardStyle,
    width: isSmallScreen ? "100%" : cardStyle.width,
  };

  return (
    <div style={responsiveCardStyle}>
      <img style={imageStyle} src={image} alt="Card Image" />
      <div style={{ padding: "15px" }}>
        <div style={titleStyle}>{title}</div>
        <div style={positionStyle}>{position}</div>
        <div style={descriptionStyle}>{description}</div>
      </div>
    </div>
  );
};

export default CardSlider;
