import React, { useEffect, useCallback, useState } from "react";
import Image from "../../Assets/Images/Prashadsir.png";
import QuoteStart from "../../Assets/Images/quoteStart.png";
import QuoteEnd from "../../Assets/Images/quoteEnd.png";
import "./styles.css";

/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-a-react-card-carousel-component
 */

const cardItems = [
  {
    id: 1,
    title: "Prasad R. Lendwe",
    post: "Founder and CEO of Finnovationz",
    copy: "I just discovered this infotech page and I'm already hooked! The articles are insightful and informative, and the design of the website is sleek and modern. I especially appreciate the diversity of topics covered, from cybersecurity to software development. Keep up the great work!",
    rating: 5,
  },
  {
    id: 2,
    title: "Dhaval Prajapati",
    post: "Founder and CEO of Finnovationz",
    copy: "I just discovered this infotech page and I'm already hooked! The articles are insightful and informative, and the design of the website is sleek and modern. I especially appreciate the diversity of topics covered, from cybersecurity to software development. Keep up the great work!",
    rating: 4,
  },
  {
    id: 3,
    title: "Janvi Shah",
    post: "Founder and CEO of Finnovationz",
    copy: "I just discovered this infotech page and I'm already hooked! The articles are insightful and informative, and the design of the website is sleek and modern. I especially appreciate the diversity of topics covered, from cybersecurity to software development. Keep up the great work!",
    rating: 3,
  },
  {
    id: 4,
    title: "Niket Patel",
    post: "Founder and CEO of Finnovationz",
    copy: "I just discovered this infotech page and I'm already hooked! The articles are insightful and informative, and the design of the website is sleek and modern. I especially appreciate the diversity of topics covered, from cybersecurity to software development. Keep up the great work!",
    rating: 2,
  },
  {
    id: 5,
    title: "Kinjal Patel",
    post: "Founder and CEO of Finnovationz",
    copy: "I just discovered this infotech page and I'm already hooked! The articles are insightful and informative, and the design of the website is sleek and modern. I especially appreciate the diversity of topics covered, from cybersecurity to software development. Keep up the great work!",
    rating: 1,
  },
];

function determineClasses(indexes, cardIndex) {
  if (indexes.currentIndex === cardIndex) {
    return "active";
  } else if (indexes.nextIndex === cardIndex) {
    return "next";
  } else if (indexes.previousIndex === cardIndex) {
    return "prev";
  } else if (indexes.secondNextIndex === cardIndex) {
    return "second-next";
  } else if (indexes.secondPreviousIndex === cardIndex) {
    return "second-prev";
  }
  return "inactive";
}

const CardCarousel = () => {
  const [indexes, setIndexes] = useState({
    previousIndex: 0,
    currentIndex: 0,
    nextIndex: 1,
    secondNextIndex: 2,
    secondPreviousIndex: 4, // Initially set to the last card
  });

  const handleCardTransition = useCallback(() => {
    if (indexes.currentIndex >= cardItems.length - 1) {
      setIndexes({
        previousIndex: cardItems.length - 1,
        currentIndex: 0,
        nextIndex: 1,
        secondNextIndex: 2,
        secondPreviousIndex: cardItems.length - 2,
      });
    } else {
      setIndexes((prevState) => ({
        previousIndex: prevState.currentIndex,
        currentIndex: (prevState.currentIndex + 1) % cardItems.length,
        nextIndex: (prevState.currentIndex + 2) % cardItems.length,
        secondNextIndex: (prevState.currentIndex + 3) % cardItems.length,
        secondPreviousIndex: prevState.previousIndex,
      }));
    }
  }, [indexes.currentIndex]);

  useEffect(() => {
    const transitionInterval = setInterval(() => {
      handleCardTransition();
    }, 2000);

    return () => clearInterval(transitionInterval);
  }, [handleCardTransition, indexes]);

  const renderStars = (rating) => {
    const totalStars = 5;
    const filledStars = "★".repeat(rating);
    const emptyStars = "☆".repeat(totalStars - rating);

    return (
      <div className="rating-stars">
        <span className="filled-stars">{filledStars}</span>
        <span className="empty-stars">{emptyStars}</span>
      </div>
    );
  };

  return (
    <div className="container">
      <ul className="card-carousel">
        {cardItems.map((card, index) => {
          const cardClass = determineClasses(indexes, index);
          return (
            <li key={card.id} className={`card ${cardClass}`}>
              <div className="rating-container">{renderStars(card.rating)}</div>
              <img src={Image} alt={card.image} className="testimonial-image" />
              <h2>{card.title}</h2>
              <h5>{card.post}</h5>
              {cardClass === "active" && (
                <>
                  <img
                    src={QuoteStart}
                    alt="quote start"
                    className="quote-image-start"
                  />
                  <p>{card.copy}</p>
                  <img
                    src={QuoteEnd}
                    alt="quote end"
                    className="quote-image-end"
                  />
                </>
              )}
              {cardClass !== "active" && <p>{card.copy}</p>}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CardCarousel;
