import { makeStyles } from '@mui/styles'
import BaseColor from '../../Config/Color'
import { FontFamily } from '../../Config/theme'
const isMobile = window.innerWidth <= 768; 
const useStyles = makeStyles({
  mainContainer: {
    height: '120vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: BaseColor.white,
    fontFamily:FontFamily.Medium,
    paddingTop:'90px'
  },
  titleStyle: {
    color: BaseColor.white,
    fontSize: 18,
    fontFamily:FontFamily.Medium
  },
  whoWeAreContent: {
    // fontSize: 18,
    // lineHeight: 2.5,
    fontFamily:FontFamily.SemiBold,
    paddingTop:'20px'
  },
  mainContaint: {
    width: isMobile ?'100%': '70%',fontFamily:FontFamily.Medium
  },
  meetOurTeamText: {
    fontSize: 24,
   fontFamily:FontFamily.Bold,
   textAlign:"center"
  },
  
 
})


export default useStyles
