/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HomeCarouseIArray } from "../../Config/staticData";
import { FontFamily } from "../../Config/theme";

const StyledRotatingBox = styled.div`
  border-radius: 12px;
  width: 370px;
  height: 100%;
  margin: 10px;
  display: inline-block;
  text-align: center;
  transform: ${({ rotationX, rotationY }) =>
    `perspective(1000px) rotateX(${rotationX}deg) rotateY(${rotationY}deg)`};
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;

  &:hover {
    cursor: pointer;
  }
`;

const Slider2 = () => {
  const [rotations, setRotations] = useState(
    Array(HomeCarouseIArray?.length).fill({ rotationX: 0, rotationY: 0 })
  );

  const handleMouseMove = (event, index) => {
    const mouseX = event.nativeEvent.offsetX;
    const mouseY = event.nativeEvent.offsetY;

    const newRotations = [...rotations];
    newRotations[index] = {
      rotationX: (mouseY / event.target.clientHeight) * 28 - 14,
      rotationY: (mouseX / event.target.clientWidth) * 28 - 14,
    };

    setRotations(newRotations);
  };

  const settings = {
    className: "center",
    centerMode: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleMouseLeave = (index) => {
    const newRotations = [...rotations];
    newRotations[index] = { rotationX: 0, rotationY: 0 };

    setRotations(newRotations);
  };
  return (
    <div>
      <Slider {...settings}>
        {HomeCarouseIArray.map((item, index) => (
          <div key={index} className="project">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <StyledRotatingBox
                rotationX={rotations[index].rotationX}
                rotationY={rotations[index].rotationY}
                onMouseMove={(event) => handleMouseMove(event, index)}
                onMouseLeave={() => handleMouseLeave(index)}
                style={{
                  // boxShadow: `rgba(127, 68, 248, 0.3) ${
                  //   -0.795459 * rotations[index].rotationX
                  // }px ${
                  //   -8.41862 * rotations[index].rotationY
                  // }px 20px`, // Adjust the coefficients if needed
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
                  
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "150px",
                    paddingTop: 10,
                  }}
                >
                 
                  <img
                    src={item.img}
                    alt="Sample Image"
                    style={{
                      maxWidth: "100%",
                      borderTopLeftRadius: "8px",
                      borderTopRightRadius: "8px",
                    }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    margin: "25px 25px",
                    fontFamily: FontFamily.SemiBold,
                  }}
                >
                  {item.name}
                </div>
                <div
                  style={{
                    margin: "25px 25px",
                    fontFamily: FontFamily.Light,
                  }}
                >
                  {item.desc}
                </div>
              </StyledRotatingBox>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Slider2;
