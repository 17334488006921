import { Grid } from "@mui/material";
import { React } from "react";
import { FontFamily } from "../../Config/theme";
import _ from "lodash";
import { whatwedoIArray } from "../../Config/staticData";

const Whatwe = () => {
  // const [whatwedata, setwhatweData] = useState([]);
  const cardStyle = {
    borderRadius: "8px",
    width: "250px",
    display: "inline-block",
    textAlign: "center",
  };

  const handleHover = (event, backgroundColor) => {
    // Increase the size of the box shadow by adjusting the third value
    event.target.style.boxShadow = `0 0 80px ${backgroundColor}`;
  };

  const handleLeave = (event) => {
    // Reset the box shadow when the hover is removed
    event.target.style.boxShadow = "none";
  };

  // useEffect(() => {
  //   whatweDatafunc();
  // }, []);

  // const whatweDatafunc = async () => {
  //   const URL = BaseSetting.apiUrl
  //   try {
  //     const response = await axios.get(
  //       URL  + "common?page=1&limit=50&placeOfContent=whatWeDo"
  //     );
  //     const result = response?.data;
  //     if (!_.isEmpty(result?.results) && _.isArray(result?.results)) {
  //       setwhatweData(result?.results);
  //     } else {
  //       setwhatweData([]);
  //     }
  //   } catch (error) {
  //     setwhatweData([]);
  //   }
  // };

  return (
    <div>
      <Grid container sx={{ margin: "20px 0px" }}>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontFamily: FontFamily.ExtraBold,
            }}
          >
            <h1>What we do</h1>
          </div>
        </Grid>

        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
      </Grid>

      <Grid container>
        <Grid container sx={{ margin: "20px 0px" }}>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <Grid container sx={{ paddingBottom: 3 }}>
              {!_.isEmpty(whatwedoIArray) &&
                _.isArray(whatwedoIArray) &&
                whatwedoIArray?.map((item, index) => {
                  // const matchingImage = whatwedoIArray?.find(
                  //   (image) => image?.name === item?.title
                  // );
                  return (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={3}
                        xl={3}
                        key={index}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingBottom: 50,
                          }}
                        >
                          <div style={cardStyle}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                margin: "10px 0px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  height: "60px",
                                  backgroundColor: item.backgroundcolor,
                                  width: "60px",
                                  alignItems: "center",
                                  borderRadius: "10px",
                                }}
                                onMouseOver={(e) =>
                                  handleHover(e, item.backgroundcolor)
                                }
                                onMouseLeave={handleLeave}
                              >
                                {/* {matchingImage && ( */}
                                <img
                                  src={item.img}
                                  alt=""
                                  style={{ width: 30, height: 30 }}
                                />
                                {/* // )} */}
                              </div>
                            </div>
                            <div
                              style={{
                                fontFamily: FontFamily.ExtraBold,
                                paddingTop: "10px",
                                textAlign: "center",
                                fontSize: 16,
                                justifyContent: "center",
                                height: "40px",
                              }}
                            >
                              {item.name}
                            </div>

                            <div
                              style={{
                                fontFamily: FontFamily.Light,
                                paddingTop: "10px",
                                textAlign: "center",
                                fontSize: 14,
                              }}
                            >
                              {item.desc}
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </>
                  );
                })}
            </Grid>
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
          <></>
        </Grid>
      </Grid>
    </div>
  );
};

export default Whatwe;
