import types from "./actions";

const initialState = {
  commonData: {},
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SET_COMMON_DATA:
      return { ...state, commonData: actions.commonData };
    default:
      return state;
  }
}
