// import { name } from '../../../package.json'
import moment from "moment";
const liveApiUrl = "http://143.244.141.111:3000/forntadmin/v1/";

const BaseSetting = {
  // siteName: name,
  siteIcon: "",
  footerText: `© ${moment().year()} All rights reserved | Powered by Vihil InfoTech`,
  GOOGLE_CLIENT_ID: "",
  // ------------------------------Client Domain----------------------------------------
  // -----------------------------------------------------------------------------------
  //   domainUrl: isLive ? liveApiUrl : localApiUrl,
  apiUrl: liveApiUrl,
  revalidate: true,
  templateKey: "",
  mapKey: "",
  endpoint: {
    statisticsData: "common?page=1&limit=50&placeOfContent=statistics",
    visionData: "common?page=1&limit=50&placeOfContent=vision",
    WhoweareData: "",
    faq: "faq",
  },
};

export default BaseSetting;
