const actions = {
  SET_ACCESS_TOKEN: "Auth/SET_ACCESS_TOKEN",

  setAccessToken: (token) => (dispatch) => {
    dispatch({
      type: actions.SET_ACCESS_TOKEN,
      accessToken: token,
    });
  },
};
export default actions;
