import React, { useState } from "react";
import { Drawer ,List, ListItemButton, ListItemIcon, ListItemText, IconButton} from "@mui/material";
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { useNavigate } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import BusinessIcon from '@mui/icons-material/Business';
import BookIcon from '@mui/icons-material/Book';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import BaseColor from "../../Config/Color";
import AccessibilityIcon from '@mui/icons-material/Accessibility';

const CDrawer =() =>{
 
    const   navigate = useNavigate();
    const Pages = [ 
        { title:'Home' ,path:'/home' , icon: <HomeIcon />},
        { title:'Capabilities' ,path:'/our-services' ,icon: <AccessibilityIcon />},
        { title:'Company' ,path:'/who-we-are' , icon: <BusinessIcon />},
        { title:'FAQ' ,path:'/FAQ' , icon: <BookIcon />},
        { title:'Contact US' ,path:'/ContactUs' , icon: <ContactPageIcon />},
       
        
        ]
    // const Pages = [ "Home", "Capabilities", "company", "Blogs", "Contact US",]
    const [openDrawer ,setOpenDrawer] =useState(false)
    return( 
<React.Fragment>
    <Drawer open={openDrawer}
        anchor="bottom"
    onClose={() =>setOpenDrawer(false)}
    
    >
        <List >
            {Pages.map((item,index) =>(<ListItemButton key={index} onClick={() =>
           { setOpenDrawer(!openDrawer);
            navigate(item.path)}
            }> 
                <ListItemIcon>
                {item.icon}
                </ListItemIcon>
                    <ListItemText style={{paddingTop:5}}>{item.title}</ListItemText>
                
                </ListItemButton>))}
           
        </List>
        
    </Drawer>
    <IconButton 
    sx={{ marginLeft:'auto'}}
    onClick={()=>setOpenDrawer(!openDrawer)}>
        <MenuOutlinedIcon  style={{color:BaseColor.white}}/>
    </IconButton>
</React.Fragment>
    )
};

export default CDrawer;