import React, { useEffect } from "react";
import { CardSlider } from "../../Components/Card/CardSlider";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import useStyles from "./styles";
import BaseColor from "../../Config/Color";
import CFooter from "../../Components/CFooter";
import CSubHeader from "../../Components/CSubHeader";
import Files from "../../Config/Files";
import { whoWeAreImagesArray } from "../../Config/staticData";
import _ from "lodash";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const WhoWeAre = () => {
  const classes = useStyles();
  // const md = useMediaQuery(theme.breakpoints.down("md"));
  const theme = useTheme();
  // const [data, setData] = useState([]);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  // const [content, setContent] = useState(null);

  // useEffect(() => {
  //   fetchData(); // Call the fetchData function when the component mounts
  //   fetchPP();
  // }, []);
  useEffect(() => {
    // Scroll to the top of the page
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(
  //       "https://api.staging.vihilinfotech.com/v1/team"
  //     );
  //     const result = response?.data;
  //     if (!_.isEmpty(result?.results) && _.isArray(result?.results)) {
  //       setData(result?.results);
  //     } else {
  //       setData([]);
  //     }
  //   } catch (error) {
  //     setData([]);
  //   }
  // };
  // const fetchPP = async () => {
  //   console.log("12234");
  //   try {
  //     const response = await fetch(
  //       "https://api.staging.vihilinfotech.com/v1/cms"
  //     );
  //     console.log("response123456778 ====>> ", response);
  //     const result = await response.json();
  //     console.log("Vision123455666---------", result?.results);
  //     // setData(result?.results);

  //     setContent(result?.results[2]?.content);
  //   } catch (error) {
  //     console.log("error ---->>> ", error);
  //     // setError(error);
  //   } finally {
  //     // setLoading(false);
  //   }
  // };
  return (
    <div className={classes.mainContainer}>
      {/* <div
      >
        <CHeader  />
      </div> */}

      <Grid
        container
        style={{
          background: BaseColor.grayColor,
          width: "100%",
        }}
      >
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
        <Grid
          item
          xs={10}
          sm={10}
          md={10}
          lg={7}
          xl={7}
          // style={{ background: 'gray' }}
        >
          <div className={classes.mainContaint}>
            <CSubHeader
              header1="Who"
              header2="we are..."
              style={{ background: BaseColor.grayColor }}
            />
            <p
            // className={classes.whoWeAreContent}
            // dangerouslySetInnerHTML={{ __html: content }}
            >
              {" "}
              At Vihil InfoTech, we believe in a systematic approach for any
              project be it complex or simple. We are a group of individuals
              with a various set of skill set varies from Digital Marketing to
              IoT/Robotics solutions. We have our dedicated team for your
              project which uses various methods such as agile Scrum & agile
              Kanban. We ensure top-notch quality, on-time delivery, and agility
              for your project.
            </p>
          </div>
        </Grid>

        {isMatch ? null : (
          <Grid
            item
            xs={0}
            sm={0}
            md={0}
            lg={3}
            xl={3}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              // background: 'red'
            }}
          >
            <img
              src={Files?.images?.aboutUsImg}
              alt=""
              style={{
                height: "50///v0000000000000h",
                width: "100%",
                objectFit: "contain",
              }}
            />
          </Grid>
        )}
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
      </Grid>
      <div
        style={{
          borderBottom: `0.5px solid ${BaseColor.grayBorder}`,
          width: "100%",
        }}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          // position: "relative",
          // bottom: "60px",
        }}
      >
        <div style={{ width: "85%" }}>
          <Grid container>
            {/* <Grid item xs={0.4} sm={0.4} md={0.4} lg={0.1} xl={0.1} /> */}

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <p
                className={classes.meetOurTeamText}
                style={{ fontSize: "24px", fontWeight: "bold", color: "#333" }}
              >
                Meet Our Team
              </p>
            </Grid>
            {/* <Grid item xs={1} sm={1} md={1} lg={0} xl={0} /> */}
          </Grid>
          {!_.isEmpty(whoWeAreImagesArray) &&
            _.isArray(whoWeAreImagesArray) && (
              <Carousel responsive={responsive} showDots={true}>
                {!_.isEmpty(whoWeAreImagesArray) &&
                  whoWeAreImagesArray?.map((item, index) => {
                    // const fileId = item.profilePicUrl.match(
                    //   /\/file\/d\/(.+?)\/view/
                    // )[1];
                    // const directDownloadUrl = `https://drive.google.com/uc?export=download&id=${fileId}`;

                    return (
                      !_.isNull(item) && (
                        <div
                          style={{
                            // position: "relative",
                            // top: "80px",
                            display: "flex",
                            // marginBottom: "20px",
                            justifyContent: "center",
                            marginTop: 20,
                          }}
                          key={index}
                        >
                          <CardSlider
                            image={item.img}
                            title={item?.name}
                            // description={item?.desc}
                            position={item?.position}
                          />
                        </div>
                      )
                    );
                  })}
              </Carousel>
            )}
        </div>
      </div>
      <br />
      {/* <Grid
        container
        sx={{
          width: "100%",

          backgroundColor: BaseColor.primary,
          paddingBottom: "50px",
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            sx={{
              fontFamily: FontFamily.Medium,
              // fontWeight: 600,
              fontSize: 35,
              lineHeight: "62.67px",
              Letter: "1%",
              color: BaseColor.white,
              textAlign: "center",
              marginTop: "30px",
            }}
          >
            Who we are engaged with...
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontFamily: FontFamily.Medium,
              paddingTop: 15,
            }}
          >
            <Typography
              sx={{
                fontFamily: FontFamily.Regular,
                // fontWeight: 400,
                fontSize: "17px",
                lineHeight: "2",
                color: BaseColor.redwhite,
                textAlign: "center",
                maxWidth: "60%",
              }}
            >
              We partner with humble, trusting leaders that think strategically.
              Businessmen and women who believe in the change they’re making,
              embrace their mission and want to bring in a partner to accelerate
              their growth.
            </Typography>
          </div>
        </Grid>
      </Grid> */}
      {/* <Grid
        container
        style={{
          backgroundColor: BaseColor.primary,
          fontFamily: FontFamily.Medium,
          paddingBottom: "30px",
        }}
      >
        <Grid item xs={1}></Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={2}
          xl={2}
          style={{ display: "flex", justifyContent: "center", padding: "10px" }}
        >
          <img src={I2} alt="CyberX" style={{ height: 48, width: 140 }} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={2}
          xl={2}
          style={{ display: "flex", justifyContent: "center", padding: "10px" }}
        >
          <img src={I3} alt="CeylonX" style={{ height: 48, width: 140 }} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={2}
          xl={2}
          style={{ display: "flex", justifyContent: "center", padding: "10px" }}
        >
          <img src={I6} alt="imagenX" style={{ height: 48, width: 140 }} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={2}
          xl={2}
          style={{ display: "flex", justifyContent: "center", padding: "10px" }}
        >
          <img src={I4} alt="ZeuX" style={{ height: 48, width: 140 }} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={2}
          xl={2}
          style={{ display: "flex", justifyContent: "center", padding: "10px" }}
        >
          <img src={I5} alt="KodeX" style={{ height: 48, width: 140 }} />
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid> */}
      {/* <Stayinhteloop /> */}

      <div>
        <CFooter />
      </div>
    </div>
  );
};
export default WhoWeAre;
