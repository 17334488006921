import { makeStyles } from '@mui/styles'
import BaseColor from '../../Config/Color'
import { FontFamily } from '../../Config/theme'

const useStyles = makeStyles({
  headerContainer: {
    width: '100%'
  },
  subHeaderContainer: {
    paddingTop: 25
  },
  subHeaderText: {
    // fontWeight: '600',
    color: BaseColor.blackColor,
     fontSize: 32,
  //  FontFamily:FontFamily.ExtraBold,
     fontFamily: FontFamily.Bold
  }
})
export default useStyles
