import { React, useEffect } from "react";
import { Grid, Box, useTheme, useMediaQuery } from "@mui/material";
import { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/Color";
import Files from "../../Config/Files";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { Blogs } from "../../Config/staticData";
import BlogsCard from "../../Components/BlogsCard";
import CFooter from "../../Components/CFooter";
import Color from '../../Config/Color'
import CardCarousel from "../../Components/CardCourasal";

const Blog = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("sm"));
   
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div style={{ paddingTop: "10%", backgroundColor: BaseColor.white }}>
      <Grid container sx={{ backgroundColor: BaseColor.white }}>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <div style={{ paddingTop: "30px" }}>
            <img alt=""
              src={Files?.images?.vector4}
              style={{
                height: 188,
                width: 269,
                display: isMatch ? "none" : "flex",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "20px",
            }}
          >
            <img alt=""
              src={Files?.images?.bell}
              style={{
                height: 116,
                width: 116,
                display: isMatch ? "none" : "flex",
              }}
            />
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={4}
          xl={4}
          sx={{ justifyContent: "center", display: "flex" }}
        >
          <div
            style={{ width: "70%", textAlign: "center", paddingTop: "30px" }}
          >
            <span
              style={{
                fontFamily: FontFamily.ExtraBold,
                fontSize: 42,
                lineHeight: "50px",
              }}
            >
              Latest Trends
            </span>{" "}
            <br />
            <span
              style={{
                fontFamily: FontFamily.ExtraBold,
                fontSize: 42,
                color: BaseColor.blueBorder,
                lineHeight: "125px",
              }}
            >
              Blogs
            </span>
            <br />
            <br />
            <span
              style={{
                fontFamily: FontFamily.Medium,
                fontSize: 20,
                color: Color.grey,
              }}
            >
              Lorem Ipsum is simply dummy text of the printing .
            </span>
            <br />
            <br />
            <br />
            <br />
            <div>
              <div
                style={{
                  backgroundColor: Color.redwhite,
                  display: "flex",
                  borderRadius: 10,
                }}
              >
                <IconButton type="submit" aria-label="search">
                  <SearchIcon style={{ fill: "grey" }} />
                </IconButton>
                <input
                  style={{
                    outline: "none",
                    borderStyle: "none",
                    width: "80%",
                    height: "50px",
                    backgroundColor: Color.redwhite,
                  }}
                  type="text"
                  placeholder="Search"
                />
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "20px",
            }}
          >
            <img
            alt=""
              src={Files?.images?.bulb}
              style={{
                height: 150,
                width: 150,
                display: isMatch ? "none" : "flex",
              }}
            />
          </div>
          <div style={{ display: "flex", paddingTop: "20px" ,paddingRight:'10px',  paddingLeft:'10px'}}>
            <img
            alt=""
              src={Files?.images?.vector3}
              style={{
                height: 188,
                width: 269,
                display: isMatch ? "none" : "flex",
              }}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container sx={{ marginTop: "5%" }}>
        <Grid item xs={1.5} sm={1.5} md={1.5} lg={1} xl={1}></Grid>
        <Grid item xs={9} sm={9} md={9} lg={4} xl={4}>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
            alt=""
              src={Files?.whoWeAreImages?.User4}
              style={{
                width: "95%",
                borderRadius: 40,
                objectFit: "contain",
              }}
            />
          </div>
        </Grid>
        <Grid item xs={1.5} sm={1.5} md={1.5} lg={0.5} xl={0.5}></Grid>
        <Grid item xs={1.5} sm={1.5} md={1.5} lg={0.5} xl={0.5}></Grid>
        <Grid
          item
          xs={9}
          sm={9}
          md={9}
          lg={5}
          xl={5}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <span
              style={{
                fontFamily: FontFamily.Bold,
                fontSize: 22,
                lineHeight: "45px",
                justifyContent: "center",
              }}
            >
              Lorem Ipsum is simply dummy text of the printing.
            </span>
            <br />
            <div style={{ paddingTop: "10px" }}>
              <span
                style={{
                  fontFamily: FontFamily.Light,
                  // fontSize: 22,
                  lineHeight: "35px",
                  textAlign: "center",
                  // color: "#777777",
                }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the .
              </span>
            </div>

            <div style={{ display: "flex", paddingTop: "40px" }}>
              <img
              alt=""
                src={Files?.images?.user}
                style={{ width: "13%", objectFit: "contain" }}
              />
              <div style={{ margin:"10px 0px 0px 20px" }}>
                <span
                  style={{ fontFamily: FontFamily.Bold }}
                  // style={{paddingTop:'20px'}}
                >
                  Name here
                </span><br />
                <span style={{ fontFamily: FontFamily.Light }}>20.12.2020</span>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={1.5} sm={1.5} md={1.5} lg={1} xl={1}></Grid>
      </Grid>

      <div style={{ display: "flex", justifyContent: "center" }}>
        {/* <div style={{paddingTop:'10vh'}}> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: "white",
            paddingTop: "40px",
            width: "88%",
          }}
        >
          <Grid container>
            {Blogs.map((item, index) => (
              <Grid
                item
                lg={4}
                xl={4}
                key={item.id}
                sx={{
                  margin: "40px 0px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {" "}
                <BlogsCard mayItem={item} />
              </Grid>
            ))}
          </Grid>
        </Box>
        {/* </div> */}
      </div>
      {/* <Stayinhteloop /> */}
      <div className="testimonial-section">
          <h2 className="text-align">Client Testimonials</h2>
          {/* <TestimonialSlider /> */}
          <CardCarousel />
      </div>
      <CFooter />
    </div>
  );
};

export default Blog;
