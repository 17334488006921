import React, { useState } from "react";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import useStyles from "./styles";
import BaseColor from "../../Config/Color";
import BaseSetting from "../../Apis/setting";
import { socialArray } from "../../Config/staticData";
import ioup from "../../Assets/Images/loupe (3) 1.png";
import theme, { FontFamily } from "../../Config/theme";
import { useNavigate } from "react-router-dom";
import color from "../../Config/Color";
import CustomModal from "../CModal";
import { MdEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
const CFooter = () => {
  const classes = useStyles();
  const [hovered, setHovered] = useState(false);
  const [contactus, setContactus] = useState(false);
  const [aboutus, setAboutus] = useState(false);
  const [TAC, setTAC] = useState(false);
  const [PAP, setPAP] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const handleHovercu = () => {
    setContactus(!contactus);
  };
  const handleHover = () => {
    setHovered(!hovered);
  };
  const handleHoverau = () => {
    setAboutus(!aboutus);
  };
  const handleHovertac = () => {
    setTAC(!TAC);
  };
  const handleHoverpap = () => {
    setPAP(!PAP);
  };
  const handleHoverindex = (index) => {
    setHoveredIndex(index);
  };
  const handleCloselottie = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div
        style={{
          background: BaseColor.secondory,
          fontFamily: FontFamily.Medium,
          overflowX: "hidden",
        }}
      >
        <Grid
          container
          style={{
            width: "100%",
            background: BaseColor.secondory,
          }}
        >
          <Grid item xs={1} sm={1} md={1} lg={0.6} xl={0.6} />
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={3}
                xl={3}
                style={{
                  paddingTop: "62px",
                  rowGap: "5px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ rowGap: "5px" }}>
                  <span className={classes.titleStyle}>Services</span>
                  <br />

                  <div
                    style={{
                      display: "flex",

                      color: color.white,
                      margin: "10px 0px",
                    }}
                  >
                    <div
                      style={{
                        // height: 30,
                        background: BaseColor.background,
                        width: "130px",
                        textAlign: "center",
                        borderRadius: "3px",
                        padding: "3px 0px ",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: FontFamily.ExtraLight,
                          fontSize: "12px",
                          color: color.white,
                        }}
                      >
                        Frontend
                      </span>
                    </div>
                    <div
                      style={{
                        // height: 30,
                        background: BaseColor.background,
                        width: "94px",
                        textAlign: "center",
                        borderRadius: "3px",
                        padding: "3px 10px ",
                        marginLeft: "10px",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: FontFamily.ExtraLight,
                          fontSize: "12px",
                          color: color.white,
                        }}
                      >
                        Backend
                      </span>
                    </div>
                  </div>

                  <div
                    style={{
                      // height: 30,

                      // width: "120px",
                      // textAlign: "center",
                      borderRadius: "3px",
                      color: color.white,
                      width: "165px",
                      background: BaseColor.background,
                      padding: "3px 0px",
                      textAlign: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: FontFamily.ExtraLight,
                        fontSize: "12px",
                        color: color.white,
                      }}
                    >
                      eCommerce
                    </span>
                  </div>

                  <div
                    style={{
                      // width: "200px",
                      display: "flex",

                      color: color.white,
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        // height: 30,
                        background: BaseColor.background,
                        // width: "94px",

                        borderRadius: "3px",
                        padding: "3px 0px ",
                        textAlign: "center",
                        width: "130px",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: FontFamily.ExtraLight,
                          fontSize: "12px",
                          color: color.white,
                        }}
                      >
                        SaaS
                      </span>
                    </div>
                    <div
                      style={{
                        // height: 30,
                        background: BaseColor.background,
                        width: "94px",
                        textAlign: "center",
                        borderRadius: "3px",
                        padding: "3px 10px ",
                        marginLeft: "10px",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: FontFamily.ExtraLight,
                          fontSize: "12px",
                          color: color.white,
                        }}
                      >
                        Progressive
                      </span>
                    </div>
                  </div>

                  <div
                    style={{
                      // height: 30,

                      // width: "120px",
                      // textAlign: "center",
                      borderRadius: "3px",

                      textAlign: "center",
                      width: "165px",
                      background: BaseColor.background,
                      fontFamily: FontFamily.ExtraLight,
                      fontSize: "12px",
                      color: color.white,
                      padding: "3px 0px ",
                      marginBottom: "10px",
                    }}
                  >
                    <span>UI/UX</span>
                  </div>

                  <div
                    style={{
                      // width: "200px",
                      display: "flex",

                      color: color.white,
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        // height: 30,
                        background: BaseColor.background,
                        width: "130px",

                        borderRadius: "3px",
                        padding: "3px 0px ",

                        fontFamily: FontFamily.ExtraLight,

                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: FontFamily.ExtraLight,
                          fontSize: "12px",
                          color: color.white,
                        }}
                      >
                        Branding
                      </span>
                    </div>
                    <div
                      style={{
                        background: BaseColor.background,

                        textAlign: "center",
                        borderRadius: "3px",
                        padding: "3px 10px ",
                        marginLeft: "10px",
                        width: "94px",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: FontFamily.ExtraLight,
                          fontSize: "12px",
                          color: color.white,
                        }}
                      >
                        Mobile
                      </span>
                    </div>
                  </div>

                  <div
                    style={{
                      // width: "200px",
                      display: "flex",

                      // paddingTop:10,
                    }}
                  >
                    <div
                      style={{
                        // height: 50,
                        background: BaseColor.background,
                        // width: "94px",
                        textAlign: "center",
                        borderRadius: "3px",
                        padding: "3px 0px ",
                        width: "130px",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: FontFamily.ExtraLight,
                          fontSize: "12px",
                          color: color.white,
                        }}
                      >
                        Custom Web
                      </span>
                    </div>
                    <div
                      style={{
                        // height: 30,
                        background: BaseColor.background,
                        // width: "94px",
                        textAlign: "center",
                        borderRadius: "3px",
                        padding: "3px 10px ",
                        marginLeft: "10px",
                        width: "94px",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: FontFamily.ExtraLight,
                          fontSize: "12px",
                          color: color.white,
                        }}
                      >
                        Web
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>
              {md ? null : (
                <Grid
                  item
                  xs={0}
                  sm={0}
                  md={0}
                  lg={1}
                  xl={1}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div
                    style={{
                      width: "1px",
                      height: "80%",
                      backgroundColor: color.white,
                      marginTop: "60px",
                    }}
                  ></div>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={3}
                xl={3}
                style={{
                  display: "flex",
                  paddingTop: "62px",
                  justifyContent: "center",
                }}
              >
                <div style={{ width: "80%" }}>
                  <span className={classes.titleStyle}>
                    Subscribe Newsletter
                  </span>
                  <br />
                  <br />
                  <div
                    style={{
                      height: "38px",

                      display: "flex",

                      backgroundColor: color.white,
                      borderRadius: "5px",
                      justifyContent: "space-between",
                    }}
                  >
                    <input
                      style={{
                        outline: "none",
                        borderStyle: "none",
                        marginLeft: "8px",
                        color: BaseColor.black50,
                        opacity: 1,
                        fontFamily: FontFamily.Medium,
                      }}
                      type="text"
                      placeholder="Enter Email address"
                    />
                    <span
                      style={{
                        width: "46",
                        height: "50",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: color.darkblue,
                        borderTopLeftRadius: 4,
                        borderBottomLeftRadius: 4,
                      }}
                    >
                      <img
                        src={ioup}
                        style={{
                          height: "17px",
                          width: "17px",
                          margin: "0px 10px",
                          borderTopRightRadius: "3px",
                        }}
                        alt=""
                      />
                    </span>
                  </div>
                  <br />

                  <div>
                    <Typography
                      sx={{
                        color: color.white,
                        fontSize: "20px",
                        fontWeight: "500px",
                        lineHeight: "190.6%",
                      }}
                    >
                      Work with us!
                    </Typography>
                  </div>
                  <br />
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <MdEmail
                      color="white"
                      style={{ marginRight: 12, marginTop: 3 }}
                      size={20}
                    />
                    <a
                      href="mailto:vihil3010@gmail.com"
                      style={{
                        color: color.white,
                        textDecoration: "none",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.color = color.blue)
                      } 
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.color = color.white)
                      }
                    >
                      <div
                        style={{
                          // color: "#0073C6",
                          fontSize: "16px",
                          fontWeight: "400px",
                          lineHeight: "190.6%",
                        }}
                        // className={classes.titleStyle}
                      >
                        vihil3010@gmail.com
                      </div>
                    </a>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <IoCall
                      color="white"
                      style={{ marginRight: 12 }}
                      size={20}
                    />
                    <Typography
                      sx={{
                        color: color.white,
                        fontSize: "14px",
                        fontWeight: "500px",
                        lineHeight: "190.6%",
                      }}
                    >
                      +91 7016421339
                    </Typography>
                  </div>
                  {/* <Typography
                    sx={{
                      color: color.white,
                      fontSize: "14px",
                      fontWeight: "500px",
                      lineHeight: "190.6%",
                      paddingTop:"10px"
                    }}
                  >
                    Address :
                  </Typography> */}
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <FaLocationDot
                      color="white"
                      style={{ marginLeft: 5, marginBottom: 5 }}
                      size={40}
                    />
                    <Typography
                      sx={{
                        color: color.white,
                        fontSize: "14px",
                        fontWeight: "500px",
                        lineHeight: "190.6%",
                        paddingTop: "5px",
                        paddingLeft: "15px",
                      }}
                    >
                      207, Sky Tatva - I, Opposite amba ashram, College Road,
                      Nadiad, Gujarat - 387001
                    </Typography>
                  </div>
                </div>
              </Grid>
              {md ? null : (
                <Grid
                  item
                  xs={0}
                  sm={0}
                  md={0}
                  lg={1}
                  xl={1}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div
                    style={{
                      width: "1px",
                      height: "80%",
                      backgroundColor: "white",
                      marginTop: "60px",
                    }}
                  ></div>
                </Grid>
              )}

              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={3}
                xl={3}
                style={{
                  padding: "62px 0px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div>
                  <span className={classes.titleStyle}>
                    Want to know more about Us?
                  </span>
                  <Typography
                    style={{
                      color: hovered ? color.blueBorder : color.mediumGrey,
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "190%",
                      cursor: "pointer",
                      paddingTop: 10,
                    }}
                    onMouseEnter={handleHover}
                    onMouseLeave={handleHover}
                    onClick={() => navigate("/how-we-work")}
                  >
                    How we work?
                  </Typography>
                  <Typography
                    style={{
                      color: aboutus ? color.blueBorder : color.mediumGrey,
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "190%",
                      cursor: "pointer",
                    }}
                    onMouseEnter={handleHoverau}
                    onMouseLeave={handleHoverau}
                    onClick={() => navigate("/who-we-are")}
                  >
                    About us?
                  </Typography>
                  <Typography
                    style={{
                      color: contactus ? color.blueBorder : color.mediumGrey,
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "190%",
                      cursor: "pointer",
                    }}
                    onMouseEnter={handleHovercu}
                    onMouseLeave={handleHovercu}
                    // onClick={() => navigate("/ContactUs")}
                  >
                    <a
                      href="mailto:vihil3010@gmail.com"
                      style={{
                        textDecoration: "none",
                        color: contactus ? color.blueBorder : color.mediumGrey,
                      }}
                    >
                      {" "}
                      Contact us?
                    </a>
                    {/* Contact us? */}
                  </Typography>
                  <br />
                  <br />
                  <span className={classes.titleStyle}>Legal Terms</span>
                  <br />
                  <Typography
                    style={{
                      color: TAC ? color.blueBorder : color.mediumGrey,
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "190%",
                      cursor: "pointer",
                      paddingTop: 15,
                    }}
                    onMouseEnter={handleHovertac}
                    onMouseLeave={handleHovertac}
                    onClick={() => setIsModalOpen(true)}
                    // onClick={() => navigate("/TermsAndCondition")}
                  >
                    Terms and conditions
                  </Typography>
                  <Typography
                    style={{
                      color: PAP ? color.blueBorder : color.mediumGrey,
                      fontSize: "16px",
                      fontWeight: "400px",
                      lineHeight: "190%",
                      cursor: "pointer",
                    }}
                    onMouseEnter={handleHoverpap}
                    onMouseLeave={handleHoverpap}
                    onClick={() => setIsModalOpen(true)}
                    // {() => navigate("/privacy-policy")}
                  >
                    Privacy Policy
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={0} sm={0} md={0} lg={1} xl={1}></Grid>
            </Grid>
          </Grid>

          {/* <Grid xs={1} sm={1} md={1} lg={0.5} xl={0.5} /> */}
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={10} xl={10} />
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={1}
              xl={1}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                height: 50,
                paddingBottom: 40,
                //  paddingLeft: '70px',
                // backgroundColor:'white'
              }}
            >
              {socialArray.map((item, index) => {
                return (
                  <div key={index}>
                    <a key={index} href={item.link}>
                      <div
                        style={{
                          height: 35,
                          width: 35,
                          borderRadius: 35,
                          background: BaseColor.transparentBlue,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginRight: 20,
                          transition: "box-shadow 0.3s ease", // Adding transition for smooth effect
                          boxShadow:
                            hoveredIndex === index
                              ? "0 0 10px rgba(255, 255, 255, 0.5)" // Apply shadow when hovered
                              : "none", // No shadow when not hovered
                        }}
                        onMouseEnter={() => handleHoverindex(index)}
                        onMouseLeave={() => handleHoverindex(-1)}
                      >
                        {item?.icon}
                      </div>
                    </a>
                  </div>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",

          background: BaseColor.whiteColor,
          fontSize: "15px",
          padding: "0px 15px",
          textAlign: "center",
          overflowX: "hidden",
        }}
      >
        <p
          style={{
            color: BaseColor.blackColor,
            fontFamily: FontFamily.Regular,
          }}
        >
          {BaseSetting?.footerText}
        </p>
      </div>
      <CustomModal open={isModalOpen} handleClose={handleCloselottie} />
    </>
  );
};
export default CFooter;
