import React from 'react'
import { Grid } from '@mui/material'
import BaseColor from '../../Config/Color'
import useStyles from './styles'

const CSubHeader = props => {
  const { header1, header2, style } = props
  const classes = useStyles()
  return (
    <div className={classes.headerContainer} style={style}>
      <Grid container className={classes.subHeaderContainer}>
        <Grid item xs={0} sm={0} md={0} lg={0} xl={0} />
        <Grid item xs={11} sm={11} md={11} lg={10} xl={10}>
          <span className={classes.subHeaderText}>{header1}</span>{' '}
          <span
            className={classes.subHeaderText}
            style={{ color: BaseColor.blueBorder}}
          >
            {header2}
          </span>
        </Grid>
        <Grid item xs={0.5} sm={0.5} md={0.5} lg={1} xl={1} />
      </Grid>
    </div>
  )
}
export default CSubHeader
