import React, { useState } from "react";
import {
  Divider,
  Button,
  Tab,
  Tabs,
  AppBar,
  Popover,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Files from "../../Config/Files";
import BaseColor from "../../Config/Color";
import CIcon from "../CIcon";
import CDrawer from "../CDrawer";
import { useNavigate } from "react-router-dom";
import { FontFamily } from "../../Config/theme";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import CustomModal from "../CModal";

const CHeader = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [arrowUp, setArrowUp] = useState(true);
  const [isCompanyitem, setIsCompanyitem] = useState(null);
  const [selectedCompanyIndex, setSelectedCompanyIndex] = useState(null);
  const [activeTabForLogo, setActiveTabForLogo] = useState(0);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const array = [
    // { title: "How we Work", path: "/how-we-work" },
    { title: "About us", path: "/who-we-are" },
    { title: "Terms  & Condition", path: "/TermsAndCondition" },
    { title: "Privacy Policy", path: "/privacy-policy" },
    { title: "Careers@Vihil", path: "vihilCareer" },
  ];
  const navigate = useNavigate();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handleArrowClick = (event) => {
    setAnchorEl(event.currentTarget);
    setArrowUp(!arrowUp);
    setIsPopoverOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null); // Close the popover
    setIsPopoverOpen(false);
  };
  const handleCloselottie = () => {
    setIsModalOpen(false);
  };
  const handleMouseEnter = (title) => {
    setIsCompanyitem(title);
  };
  const handleMouseLeave = () => {
    setIsCompanyitem(null);
  };

  const handleDropdownItemClick = (index, title) => {
    if (
      array[index].path === "vihilCareer" ||
      array[index].path === "/privacy-policy" ||
      array[index].path === "/TermsAndCondition"
    ) {
      setIsModalOpen(true);
      console.log("Hello Kinjal hear");
    } else {
      setActiveTabForLogo(index);
      setSelectedCompanyIndex(index);
      setIsCompanyitem(title);
      navigate(array[index].path);
      setAnchorEl(null); // Close the dropdown after selection
      setTabValue(2);
    }
  };

  const handleLogoClick = () => {
    setActiveTabForLogo(0); // Set activeTabForLogo to 0 (Home)
    setTabValue(0); // Set tabValue to 0 (Home)
    navigate("/");
    window.scrollTo(0, 0);
  };

  return (
    <AppBar
      // sx={{ background: Color.primary }}
      elevation={0}
    >
      <Toolbar>
        <CIcon
          src={Files.svgIcons.logo}
          style={{
            cursor: "pointer",
            height: isMatch ? 80 : "100%",
            width: isMatch ? 150 : 180,
            objectFit: "contain",
            // padding:15
            paddingLeft: 15,
          }}
          onClick={handleLogoClick}
        />
        {isMatch ? (
          <>
            <CDrawer />
          </>
        ) : (
          <>
            <Tabs
              sx={{ marginLeft: "auto", borderBottomWidth: "5px solid" }}
              textColor={BaseColor.white}
              TabIndicatorProps={{
                style: {
                  backgroundColor: BaseColor.blueBorder,
                  height: 4,
                },
              }}
              value={tabValue}
              onChange={handleTabChange}
            >
              
              <Tab
                label="Home"
                onClick={() => {
                  if (window.location.pathname !== "/home") {
                    navigate("/home");
                  }
                }}
                style={{
                  fontFamily: FontFamily.Bold,
                  textTransform: "initial",
                  marginRight: "50px",
                  fontSize: 14,
                  borderBottomWidth: 2,
                  borderBottomColor:
                    activeTabForLogo === 0
                      ? BaseColor.blueBorder
                      : "transparent",
                }}
              />
             
              <Tab
                label="Capabilities"
                onClick={() => navigate("/our-services")}
                style={{
                  fontFamily: FontFamily.Bold,
                  fontSize: 14,
                  textTransform: "initial",
                  marginRight: "50px",
                  borderBottomWidth: 2,
                }}
              />

<Tab
                label="How We Work"
                onClick={() => {
                  if (window.location.pathname !== "/how-we-work") {
                    navigate("/how-we-work");
                  }
                }}
                style={{
                  fontFamily: FontFamily.Bold,
                  textTransform: "initial",
                  marginRight: "50px",
                  fontSize: 14,
                  borderBottomWidth: 2,
                  borderBottomColor:
                    activeTabForLogo === 0
                      ? BaseColor.blueBorder
                      : "transparent",
                }}
              />
              <Tab
                label="Company"
                //  onClick={handleArrowClick}
                onMouseEnter={handleArrowClick}
                style={{
                  fontFamily: FontFamily.Bold,
                  textTransform: "initial",
                  marginRight: "50px",
                  fontSize: 14,
                  cursor: "pointer",
                  borderBottomWidth: 2,
                }}
              />
              {!isPopoverOpen ? (
                <IoIosArrowDown style={{ marginLeft: -60,marginTop: 18}} />
           ) : ( 
               <IoIosArrowUp style={{ marginLeft: -60,marginTop: 18 }} /> 
            )} 
              <Tab
                label="FAQ"
                onClick={() => navigate("/FAQ")}
                sx={{ borderBottomWidth: 2 }}
                style={{
                  fontFamily: FontFamily.Bold,
                  textTransform: "initial",
                  marginLeft: "50px",
                  fontSize: 14,
                  borderBottomWidth: 5,
                }}
              />
            </Tabs>
            <div style={{}}>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                onMouseDown={handleClose}
                anchorOrigin={
                  {
                    // vertical: "bottom",
                    // horizontal: "center",
                  }
                }
                transformOrigin={{
                  // vertical: "top",
                  // horizontal: "center",
                  width: "80%",
                  // borderColor:BaseColor.grayBorder
                }}
                anchorPosition={
                  {
                    // left: 20,
                    // top: 10,
                  }
                }
                sx={{ margin: "40px 0px 0px 0px" }}
              >
                <div
                  onMouseLeave={handleClose}
                  // onMouseDown={(e) => {
                  //   e.stopPropagation();}}
                  style={{
                    backgroundColor: BaseColor.vdarkGray,
                    opacity: 0.9,
                    borderColor: BaseColor.grey10,
                    borderWidth: "1px solid",
                    width: "180px",
                    border: "1px solid #646464",
                    cursor: "pointer",
                    padding: "5px 0px 0px 0px",

                    // justifyContent:'center' ,alignItems:'center'
                  }}
                >
                  {/* Content for the dropdown */}
                  {array.map((item, index) => {
                    return (
                      <div
                        onClick={() =>
                          handleDropdownItemClick(index, item.title)
                        }
                        key={index}
                      >
                        <div>
                          <div
                            style={{
                              color: BaseColor.blueBorder,
                              fontFamily: FontFamily.Bold,
                              justifyContent: "center",
                              textAlign: "center",
                              borderColor: BaseColor.blackColor,
                              borderBottomWidth: 2,
                              padding: 2,
                              // borderBottom: C
                              //   ? "2px solid red"
                              //   : "2px solid transparent",
                            }}
                          >
                            {/* <span>{item.title}</span> */}
                            <div
                              style={{
                                justifyContent: "center",
                                borderColor: BaseColor.blackColor,
                                borderWidth: 2,
                                // borderBottom: isCompanySelected
                                //   ? "2px solid red"
                                //   : "2px solid transparent",
                              }}
                            >
                              <span
                                style={{
                                  color:
                                    isCompanyitem === item.title
                                      ? BaseColor.white
                                      : BaseColor.blueBorder,
                                  fontFamily: FontFamily.Regular,
                                }}
                                onMouseEnter={() =>
                                  handleMouseEnter(item.title)
                                }
                                onMouseLeave={handleMouseLeave}
                              >
                                {item.title}
                              </span>
                              {index < array?.length - 1 && (
                                <Divider
                                  style={{
                                    background:
                                      selectedCompanyIndex === index
                                        ? BaseColor.white
                                        : BaseColor.gray,
                                    marginTop: "5px",
                                  }}
                                  variant="middle"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {/* <Button onClick={handleClose}>Close Dropdown</Button> */}
                </div>
              </Popover>
            </div>

            <Button
              variant="outlined"
              sx={{ margin: 3, fontSize: 14, marginLeft: "50px" }}
              // onClick={() => navigate("/ContactUs")}
            >
              {/* Contact us */}
              <a
                href="mailto:vihil3010@gmail.com"
                style={{
                  textDecoration: "none",
                  color: BaseColor.white,
                  fontFamily: FontFamily.Bold,
                }}
              >
                {" "}
                Contact us
              </a>
            </Button>
          </>
        )}
        <CustomModal open={isModalOpen} handleClose={handleCloselottie} />
      </Toolbar>
      {/* <CDrawer /> */}
    </AppBar>
  );
};
export default CHeader;
