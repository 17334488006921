import types from "./actions";

const initialState = {
  accessToken: "",
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.SET_ACCESS_TOKEN:
      return { ...state, accessToken: actions.accessToken };
    default:
      return state;
  }
}
