import { makeStyles } from '@mui/styles'
import BaseColor from '../../Config/Color'
import { FontFamily } from '../../Config/theme'

const useStyles = makeStyles({
  mainContainer: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    background: BaseColor.white
  },
  headerText: {
    color: BaseColor.headigTextColor,
    fontSize: 26,
    fontWeight: '600',
    textAlign: 'center',
    padding: 0,
    margin: '10px 0px 10px 10px',
   fontFamily: FontFamily.Bold
  },
  headerDesText:{
    color: BaseColor.gray,
    fontSize: 18,
    padding: 0,
    margin: 0,
    textAlign: 'center',
    fontFamily:FontFamily.Regular
  }
})
export default useStyles
