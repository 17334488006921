import{
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
  useTheme ,useMediaQuery
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MySVGBackground from "../../Assets/Svg/Rectangle126.svg";
import Files from "../../Config/Files";
import CFooter from "../../Components/CFooter";
import { FontFamily } from "../../Config/theme";
import CSubHeader from "../../Components/CSubHeader";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { motion } from 'framer-motion';
import Color from '../../Config/Color'
const ContactUs = () => {
  useEffect(() => {
    // Scroll to the top of the page
    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
     });
    
  }, []);
  const containerStyle = {
    backgroundImage: `url(${MySVGBackground})`,
    backgroundSize: "cover", // Adjust as needed
    backgroundRepeat: "no-repeat", // Adjust as needed

    width: "100%",
  };
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState('');
  const [massageError, setMassageError] = useState('');
  const [arrowHovered, setArrowHovered] = useState(false);

  const screenWidth = window.innerWidth;
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("sm"));
  const isMatch1 = useMediaQuery(theme.breakpoints.down("md"));



  const handlePostRequest = () => {
    if (isValidForm()) {
      postcall()
    }
  };

  const handleArrowHover = () => {
    setArrowHovered(true);
  };

  const handleArrowUnhover = () => {
    setArrowHovered(false);
  };
  const isValidForm = () => {
    const isNameValid = name.trim() !== '';
    const isEmailValid = email.trim() !== '';
    const isMassageValid = message.trim() !== '';

    setNameError(isNameValid ? '' : 'Name is required');
    setEmailError(isEmailValid ? '' : 'Invalid email format');
    setMassageError(isMassageValid ? '' : 'Message is required');

    return isNameValid && isEmailValid && isMassageValid;
  };

  const postcall = async () => {
    try {
      const response = await fetch('https://api.staging.vihilinfotech.com/v1/contactus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any other headers if needed
        },
        body: JSON.stringify({
             email : email ,
             message :  message,
             name : name
        }),
      });
       
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setEmail('');
      setName('');
      setMessage('');
    } catch (error) {
      setEmail('');
      setName('');
      setMessage('');
    }
  };

  

  return (
    <>
    {/* <div>
      <CHeader />
    </div> */}
      <Grid container style={{ marginBottom: "20px", paddingTop: isMatch? "20px" :"0px" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height:  isMatch ? "730px":"850px" && isMatch1 ? "770px"  : "844px",
          }}
        >



          <div style={{ width: "80%" ,paddingTop:80 }}>
          <CSubHeader header1='Contact ' header2='US' style={{fontSize:30 ,fontFamily:FontFamily.ExtraBold}} />
            <br />
            <form noValidate autoComplete="off">
            <FormControl style={{ width: '100%',paddingTop: isMatch1? '10px': '-10px'  }}>
      <Typography style={{fontFamily: FontFamily.Bold}}>Your Name</Typography>
     
      <div style={{ paddingTop: '10px' }}>
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Write your name"
          value={name}
          onChange={(event) => {setName(event.target.value);
            if (event.target.value && nameError) {
              setNameError('');
            }
          }}
          required
          error={!!nameError}
          helperText={nameError}
          style={{borderColor:Color.borderColor }}
          InputProps={{
            style: {
              color: Color.palceholder2, // Change the placeholder text color here
            },}}
        />
      </div>
      <br />
      <br />
      <Typography style={{fontFamily: FontFamily.Bold}}> Your E-mail</Typography>
      <div style={{ paddingTop: '10px' }}>
      <TextField
          variant="outlined"
          fullWidth
          placeholder="Sample@gmail.com"
          value={email}
          onChange={(event) => {setEmail(event.target.value);
            if (event.target.value && emailError) {
              setEmailError('');
            }
          }}
          error={!!emailError}
          helperText={emailError}
         style={{borderColor:Color.borderColor }}
          InputProps={{
            style: {
              color: Color.palceholder2, // Change the placeholder text color here
            },}}
        />
      </div>
      <br />
      <br />
      <Typography style={{fontFamily: FontFamily.Bold}}>Message</Typography>
      <div style={{ paddingTop: '10px' }} />
      <TextField
        variant="outlined"
        multiline
        rows={isMatch1 ? 6 : 8}
        placeholder="Write here..........."
        fullWidth
        value={message}
        onChange={(event) => {setMessage(event.target.value);
          if (event.target.value && massageError) {
            setMassageError('');
          }}}
        error={!!massageError}
        helperText={massageError}
        style={{borderColor:Color.borderColor }}
        InputProps={{
          style: {
            color: Color.palceholder2, // Change the placeholder text color here
          },}}
      />
      <br />
      <br />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: isMatch ? "5px" :'15px' && isMatch1 ? "20px" :"15px",
        }}
      >
        
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handlePostRequest();
          }}
          onMouseOver={handleArrowHover}
        onMouseOut={handleArrowUnhover}
          style={{
            width: '179px',
            background: Color.verydarkblue,
            fontFamily: 'FontFamily.Medium',
            fontSize: 18,
            height:50,
            borderRadius:0
          }}
          whileHover={{ x: 20 }}
        >
          Send
          <motion.div
             animate={{ x: arrowHovered ? 20 : 0 }}
            style={{ display: 'inline-block', paddingLeft: '10px' }}
          >
            <ArrowRightAltIcon style={{ fontSize: 30, paddingTop: '10px' }} />
          </motion.div>
        </Button>
      </div>
    </FormControl>
            </form>
          </div>
        </Grid>

        {screenWidth <= 600 ? (
          <>
            <Grid
              container
              sx={{
                backgroundColor: Color.blackColor,
                color: Color.white,
                padding: "30px 0px",
              }}
            >
              <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
              <Grid item xs={10} sm={10} md={5} lg={5}>
                <div >
                  <div>
                    <img
                      src={Files?.svgIcons?.ball}
                      style={{ height: "33px", width: "35px" }}
                      alt="ball"
                    />
                  </div>
                  <div>
                    <span style={{fontFamily:FontFamily.ExtraBold}}>Address</span>
                    <br /><br />
                    <span
                      style={{
                        // width: "396px",
                        // height: "63px",
                        // flexShrink: "0px",
                        
                       fontFamily:FontFamily.Medium
                      }}
                    >
                      207, Sky Tatva-1, Opp. Amba Ashram, College road, Nadiad, Gujarat, India.
                      <br /> 
                      {/* VIC Australia */}
                    </span>
                  </div>
                </div>
              </Grid>
              {/* <Grid
                item
                xs={5}
                sm={5}
                md={5}
                lg={5}
                style={{ display: "flex", justifyContent: "center",alignItems:"center" ,paddingTop:"21px"}}
              >
                <div>
                  <div style={{fontFamily:FontFamily.Regular}}>+61 420 992 332</div>
                  <div style={{fontFamily:FontFamily.Regular}}>info@afq.com.au</div>
                  <div style={{fontFamily:FontFamily.Regular}}>www.afqtech.com.au</div>
                </div>
              </Grid> */}
              <Grid item xs={1} sm={1} md={1} lg={1}></Grid>
            </Grid>
            
          </>
        ) : (
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            style={containerStyle}
          >
            <div
              style={{
                display: "flex",
                height: "844px",
                width: "100%",
                alignItems: "flex-end",
              }}
            >
              <div style={{ width: "100%" }}>
                <div
                  style={{
                    color: Color.geryred,
                    display: "flex",
                    padding: "0px 0px 30px 30px",
                    paddingBottom:'80px'
                  }}
                >
                  <div>
                    <img
                      src={Files?.svgIcons?.ball}
                      style={{ height: "35px", width: "35px",position:"relative",top:"5px" }}
                      alt="ball"
                    />
                  </div>
                  <div>
                    <span style={{fontSize:24, fontFamily:FontFamily.SemiBold}}>Address</span>
                    <br /> <br />
                    <span
                     style={{lineHeight:"39px", fontSize:20 , fontFamily:FontFamily.SemiBold }}
                    >
                      207, Sky Tatva-1, Opp Amba Ashram, College road,   <br />
                      <span style={{lineHeight:"39px", fontFamily:FontFamily.SemiBold, fontSize:20 }}>  Nadiad, Gujarat, India. </span>
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    color: Color.white,
                  }}
                >
                  <div
                    style={{
                      border: "1px solid white",
                      width: "100%",
                      padding: "25px 0px",
                      textAlign: "center",

                      
                      fontFamily:FontFamily.SemiBold , fontSize:16
                    }}
                  >
                   +91 7016421339
                  </div>
                  <a href="mailto:vihil3010@gmail.com" style={{color: Color.white ,width:'100%',textDecoration:'none'}}>
                  <div
                    style={{
                      border: "1px solid white",
                      width: "100%",
                      padding: "25px 0px",
                      textAlign: "center",
                      fontFamily:FontFamily.SemiBold , fontSize:16
                    }}
                  >
                    vihil3010@gmail.com
                  </div></a>
                  <div
                    style={{
                      border: "1px solid white",
                      width: "100%",
                      padding: "25px 0px",
                      textAlign: "center",
                      fontFamily:FontFamily.SemiBold , fontSize:16
                    }}
                  >
                    www.vihilinfotech.com
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        )}
      </Grid>
     


      <div style={{ position: "relative", bottom: "20px" }}>
        <CFooter />
      </div>
    </>
  );
};

export default ContactUs;
