import {React ,useEffect} from "react"



const Login = () => {
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <span>Login</span>
    </div>
  );
};

export default Login;
