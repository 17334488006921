import {
  AiOutlineInstagram,
  AiFillLinkedin,
  AiOutlineTwitter,
} from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";
import BaseColor from "./Color";
import Files from "./Files";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { HiOutlineBuildingLibrary } from "react-icons/hi2";
import { FaScrewdriverWrench } from "react-icons/fa6";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { TbBellRinging2Filled } from "react-icons/tb";
import { MdComputer } from "react-icons/md";
import { FaAward } from "react-icons/fa";

export const headerArray = [
  {
    id: 1,
    name: "Home",
    path: "/who-we-are",
  },
  {
    id: 2,
    name: "Capabilities",
    path: "/faqs",
  },
  {
    id: 3,
    name: "Company",
    path: "/our-services",
  },
  {
    id: 4,
    name: "Blogs",
    path: "/login",
  },
];

export const socialArray = [
  {
    id: 1,
    icon: (
      <AiOutlineInstagram
        style={{ fontSize: 20, color: BaseColor.blueBorder }}
      />
    ),
    link: "https://www.instagram.com/vihilinfotech/",
  },
  {
    id: 2,
    icon: (
      <AiFillLinkedin style={{ fontSize: 20, color: BaseColor.blueBorder }} />
    ),
    link: "https://in.linkedin.com/in/vihil-infotech-5a78b5266",
  },
  {
    id: 3,
    icon: (
      <AiOutlineTwitter style={{ fontSize: 20, color: BaseColor.blueBorder }} />
    ),
    link: " https://twitter.com/VihilInfoTech",
  },
  {
    id: 4,
    icon: <BsFacebook style={{ fontSize: 20, color: BaseColor.blueBorder }} />,
    link: "https://www.facebook.com/profile.php?id=100090324282115&mibextid=ViGcVu",
  },
];

export const ServicesArray = [
  {
    id: 1,
    title: "1. Mobile App Development ",
    desc1:
      "Transform your ideas into powerful, cross-platform mobile experiences with [Vihil infotech]. Specializing in React Native development, we create sleek and efficient applications that run seamlessly on both iOS and Android. Our expert team combines innovative design with agile development to deliver a standout user experience. From concept to deployment, we prioritize excellence, ensuring your app stands out in the competitive digital landscape. Elevate your mobile presence with [Vihil infotech] – where innovation meets efficiency.",
    desc2: "",
    img: Files?.servicesImages?.servicesImg1,
  },
  {
    id: 2,
    title: "2. Web Development ",
    desc1:
      "Welcome to [Vihil infotech], your destination for cutting-edge web development powered by ReactJS and NextJS. Our expert team crafts visually stunning and high-performance websites tailored to your unique needs. With a focus on innovation and user experience, we bring your digital presence to life. Whether you're a startup or an established business, we specialize in creating dynamic and responsive web solutions. Elevate your online presence with [Vihil infotech] – where ReactJS and NextJS expertise meet excellence.",
    desc2: "What, Why and How?",
    img: Files?.servicesImages?.servicesImg2,
  },
  {
    id: 3,
    title: "3. Cross platform mobile app",
    desc1:
      "Embark on a journey of cross-platform mobile app excellence with [Vihil infotech]. Specializing in React Native development, we seamlessly bring your vision to life on both iOS and Android platforms. Our seasoned team combines innovation and efficiency to deliver sleek and feature-rich applications. Whether you're a startup with a groundbreaking idea or an enterprise seeking digital expansion, [Vihil infotech] is your trusted partner for top-notch cross-platform mobile solutions. Elevate your mobile presence – where React Native expertise meets unparalleled quality.",
    desc2: "",

    img: Files?.servicesImages?.servicesImg3,
  },
  {
    id: 4,
    title: "4. PWA Development",
    desc1: `Step into the future of web experiences with [Vihil infotech]. As pioneers in Progressive Web App (PWA) development, we seamlessly blend web and app functionalities for a responsive and engaging user journey. Our expert team leverages cutting-edge technologies, including ReactJS and NextJS, to create high-performance PWAs tailored to your business needs. Elevate your online presence with a fast, reliable, and immersive user experience. Choose [Vihil infotech] for PWA development excellence – where innovation meets the web.`,
    desc2: "",
    img: Files?.servicesImages?.servicesImg4,
  },
  {
    id: 5,
    title: "5. Desktop Application Development",
    desc1: `Empower your business with robust desktop applications crafted by [Vihil infotech]. Specializing in efficient development using cutting-edge technologies, such as ReactJS and Electron, we deliver powerful and intuitive desktop solutions. Our expert team ensures seamless integration and optimal performance for a superior user experience. Whether you're a startup or an established enterprise, [Vihil infotech] is your trusted partner for bespoke desktop applications. Elevate your digital capabilities – where innovation converges with desktop development excellence.`,
    desc2: "",
    img: Files?.servicesImages?.servicesImg5,
  },
  {
    id: 6,
    title: "6. Chatbot Development",
    desc1: `Transform customer engagement with intelligent chatbot solutions from [Vihil infotech]. Specializing in Chatbot Development, we leverage state-of-the-art technologies to create conversational interfaces that enhance user interactions. Our expert team designs and implements chatbots tailored to your business needs, improving efficiency and customer satisfaction. Whether you're a startup looking to automate processes or an enterprise aiming for personalized customer support, [Vihil infotech] is your partner in driving innovation through Chatbot Development. Elevate your digital conversations – where technology meets intelligent communication.`,
    desc2: "",
    img: Files?.servicesImages?.servicesImg6,
  },
];

export const whoWeAreImagesArray = [
  {
    id: 1,
    img: Files.whoWeAreImages?.User1,
    desc: `Businessmen`,
    position: `(CEO)`,
    name: "Bharat Desai",
  },
  {
    id: 2,
    img: Files.whoWeAreImages?.User2,
    desc: `From automation to advanced analytics and seamless experiences`,
    position: `(CTO)`,
    name: "Manish Shah",
  },
  {
    id: 3,
    img: Files.whoWeAreImages?.User3,
    desc: `From automation to advanced analytics and seamless experiences`,
    position: `( Project manager and Fullstack developer)`,
    name: "Jay shah",
  },
  {
    id: 4,
    img: Files.whoWeAreImages?.User4,
    desc: `From automation to advanced analytics and seamless experiences`,
    position: `(Sr. Frontend developer)`,
    name: "Heer patel",
  },
  {
    id: 5,
    img: Files.whoWeAreImages?.User5,
    desc: `From automation to advanced analytics and seamless experiences`,
    position: `(Hr and Sr. QA)`,
    name: "Dhaval prajapati",
  },
  {
    id: 6,
    img: Files.whoWeAreImages?.User6,
    desc: `From automation to advanced analytics and seamless experiences`,
    position: `(Sr. backend developer and server handling)`,
    name: "Mihir prajapati",
  },
  {
    id: 7,
    img: Files.whoWeAreImages?.User7,
    desc: `From automation to advanced analytics and seamless experiences`,
    position: `(Sr. frontend Developer)`,
    name: "Dip pathak",
  },
  {
    id: 8,
    img: Files.whoWeAreImages?.User8,
    desc: `From automation to advanced analytics and seamless experiences`,
    position: `(Jr. frontend developer)`,
    name: "Kinjal patel",
  },
  {
    id: 9,
    img: Files.whoWeAreImages?.User9,
    desc: `From automation to advanced analytics and seamless experiences`,
    position: `(Jr. frontend developer)`,
    name: "Jaydeep panchal",
  },
  {
    id: 10,
    img: Files.whoWeAreImages?.User10,
    desc: `From automation to advanced analytics and seamless experiences`,
    position: `(BDE)`,
    name: "Shaami Rana",
  },
  {
    id: 11,
    img: Files.whoWeAreImages?.User11,
    desc: `From automation to advanced analytics and seamless experiences`,
    position: `(BA)`,
    name: "Niket patel",
  },
];


 export const technologyiconArray =[
  {
    id: 1,
    img: Files.technologyIcon.react,
    desc: `React`,
    hight:50,
    width:50.
   
  },
  {
    id: 2,
    img: Files.technologyIcon.android,
    desc: `Android`,
    hight:50,
    width:50.
   
  },
  {
    id: 3,
    img: Files.technologyIcon.ios,
    desc: `ios`,
    hight:50,
    width:50.
  },
  {
    id: 4,
    img: Files.technologyIcon.nodejs,
    desc: `React`,
    hight:50,
    width:50.
   
  },
 ];
 export const webtechnologyiconArray =[
  {
    id: 1,
    img: Files.technologyIcon.react,
    desc: `React`,
    hight:50,
    width:50.
   
  },
  {
    id: 2,
    img: Files.technologyIcon.nextjs,
    desc: `Android`,
    hight:50,
    width:50.
   
  },
  {
    id: 3,
    img: Files.technologyIcon.javascript,
    desc: `ios`,
    hight:50,
    width:50,
  },
  {
    id: 4,
    img: Files.technologyIcon.html,
    desc: `ios`,
    hight:50,
    width:50,
  },
  {
    id: 5,
    img: Files.technologyIcon.wordPress,
    desc: `ios`,
    hight:50,
    width:50,
  },
  {
    id: 6,
    img: Files.technologyIcon.firebase,
    desc: `ios`,
    hight:55,
    width:60,
  },
  {
    id: 7,
    img: Files.technologyIcon.shopyfy,
    desc: `ios`,
    hight:50,
    width:50,
  },
 ];
 export const dashbaordtechnologyiconArray =[
  {
    id: 1,
    img: Files.technologyIcon.react,
    desc: `React`,
    hight:50,
    width:50.
   
  },
  {
    id: 2,
    img: Files.technologyIcon.nextjs,
    desc: `Android`,
    hight:50,
    width:50.
   
  },
  {
    id: 3,
    img: Files.technologyIcon.javascript,
    desc: `ios`,
    hight:50,
    width:50,
  },
  {
    id: 4,
    img: Files.technologyIcon.html,
    desc: `ios`,
    hight:50,
    width:50,
  },
  {
    id: 5,
    img: Files.technologyIcon.wordPress,
    desc: `ios`,
    hight:50,
    width:50,
  },
  {
    id: 6,
    img: Files.technologyIcon.firebase,
    desc: `ios`,
    hight:55,
    width:60,
  },
  {
    id: 7,
    img: Files.technologyIcon.shopyfy,
    desc: `ios`,
    hight:50,
    width:50,
  },
  {
    id: 8,
    img: Files.technologyIcon.aws,
    desc: `ios`,
    hight:60,
    width:60,
  },
  {
    id: 9,
    img: Files.technologyIcon.vuejs,
    desc: `ios`,
    hight:45,
    width:50,
  },
  
 ];
 export const chatboardtechnologyiconArray =[
  {
    id: 1,
    img: Files.technologyIcon.nodejs,
    desc: `React`,
    hight:50,
    width:50.
   
  },
  {
    id: 2,
    img: Files.technologyIcon.phpweb,
    desc: `Android`,
    hight:50,
    width:50.
   
  },
  {
    id: 3,
    img: Files.technologyIcon.python,
    desc: `ios`,
    hight:50,
    width:50,
  },
  {
    id: 4,
    img: Files.technologyIcon.firebase,
    desc: `ios`,
    hight:50,
    width:50,
  },
  {
    id: 5,
    img: Files.technologyIcon.aws,
    desc: `ios`,
    hight:50,
    width:50,
  },
 
  
 ];
 export const crosstechnologyiconArray =[
  {
    id: 1,
    img: Files.technologyIcon.react,
    desc: `React`,
    hight:50,
    width:50.
   
  },
  {
    id: 2,
    img: Files.technologyIcon.flutter,
    desc: `Android`,
    hight:42,
    width:40.
   
  },
  {
    id: 3,
    img: Files.technologyIcon.ionic,
    desc: `ios`,
    hight:50,
    width:70,
  },
  {
    id: 4,
    img: Files.technologyIcon.native,
    desc: `ios`,
    hight:50,
    width:50,
  },
 
 
  
 ];
 export const pwatechnologyiconArray =[
  {
    id: 1,
    img: Files.technologyIcon.react,
    desc: `React`,
    hight:50,
    width:50.
   
  },
  {
    id: 2,
    img: Files.technologyIcon.angular,
    desc: `Android`,
    hight:55,
    width:55
   
  },
  {
    id: 3,
    img: Files.technologyIcon.ionic,
    desc: `ios`,
    hight:50,
    width:70,
  },
  {
    id: 4,
    img: Files.technologyIcon.vuejs,
    desc: `ios`,
    hight:45,
    width:50,
  },
 
 
  
 ];
export const services = [
  {
    name: "Fronted",
  },
  {
    name: "Backend",
  },
  {
    name: "eCommerce",
  },
  {
    name: "saaS",
  },
  {
    name: "progessive",
  },
  {
    name: "UI/UX",
  },
  {
    name: "Branding",
  },
  {
    name: "Mobile",
  },
  {
    name: "Custom Web",
  },
  {
    name: "Web",
  },
];

export const Blogs = [
  {
    id: 1,
    img: Files.BlogsImages?.BlogsImage1,
    desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the .`,
    name: `Lorem Ipsum is simply dummy text of the printing.`,
    user: Files.BlogsUsersI?.BlogsUsersI1,
  },
  {
    id: 2,
    img: Files.BlogsImages?.BlogsImage2,
    desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the .`,
    name: `Lorem Ipsum is simply dummy text of the printing.`,
    user: Files.BlogsUsersI?.BlogsUsersI2,
  },
  {
    id: 3,
    img: Files.BlogsImages?.BlogsImage3,
    desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the .`,
    name: `Lorem Ipsum is simply dummy text of the printing.`,
    user: Files.BlogsUsersI?.BlogsUsersI3,
  },
  {
    id: 4,
    img: Files.BlogsImages?.BlogsImage4,
    desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the .`,
    name: `Lorem Ipsum is simply dummy text of the printing.`,
    user: Files.BlogsUsersI?.BlogsUsersI4,
  },
  {
    id: 5,
    img: Files.BlogsImages?.BlogsImage5,
    desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the .`,
    name: `Lorem Ipsum is simply dummy text of the printing.`,
    user: Files.BlogsUsersI?.BlogsUsersI5,
  },
  {
    id: 6,
    img: Files.BlogsImages?.BlogsImage6,
    desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the .`,
    name: `Lorem Ipsum is simply dummy text of the printing.`,
    user: Files.BlogsUsersI?.BlogsUsersI6,
  },
];
export const cardData = [
  {
    title: "01.",
    content: "Research",
    dis: "Gethering Information from all around",
    color: BaseColor.blue,
    icon: (
      <ArrowCircleRightOutlinedIcon
        fontSize="large"
        style={{ position: "relative", top: 10 }}
      />
    ),
  },
  {
    title: "02.",
    content: "Plan",
    dis: "Effective strategies for favorable outcomes",
    color: BaseColor.orannge,
    icon: (
      <ArrowCircleRightOutlinedIcon
        fontSize="large"
        style={{ position: "relative", top: 10 }}
      />
    ),
  },
  {
    title: "03.",
    content: "Implements",
    dis: "Timely executions as per the plan",
    color: BaseColor.skyBlue,
    icon: (
      <ArrowCircleRightOutlinedIcon
        fontSize="large"
        style={{ position: "relative", top: 10 }}
      />
    ),
  },
  {
    title: "04.",
    content: "Test and Deliver",
    dis: "Making a successful launch",
    color: BaseColor.green,
    icon: (
      <ArrowCircleRightOutlinedIcon
        fontSize="large"
        style={{ position: "relative", top: 10 }}
      />
    ),
  },
  {
    title: "05.",
    content: "Optimize",
    dis: "Steadyily climbing up the Hill",
    color: BaseColor.darkPink,
    icon: (
      <CheckCircleOutlineOutlinedIcon
        fontSize="large"
        style={{ position: "relative", top: 10 }}
      />
    ),
  },
];

export const imagearray = [
  {
    id: 1,
    img: Files?.arrayImage.first,
    name: `Huge Font libarary`,
    desc: `Set ut perspiciats unde omis iste natus erroe sit volupatem accusantium doloremque`,
    user: Files.BlogsUsersI?.BlogsUsersI1,
  },
  {
    id: 2,
    img: Files?.arrayImage.second,
    name: `Easy Documentates.`,
    desc: `Set ut perspiciats unde omis iste natus erroe sit volupatem accusantium doloremque`,
    user: Files.BlogsUsersI?.BlogsUsersI2,
  },
  {
    id: 3,
    img: Files?.arrayImage.thrid,
    name: `Fully Customizable`,
    desc: `Set ut perspiciats unde omis iste natus erroe sit volupatem accusantium doloremque`,
    user: Files.BlogsUsersI?.BlogsUsersI3,
  },
  {
    id: 4,
    img: Files?.arrayImage.thrid,
    name: `Fully Customizable`,
    desc: `Set ut perspiciats unde omis iste natus erroe sit volupatem accusantium doloremque`,
    user: Files.BlogsUsersI?.BlogsUsersI3,
  },
  {
    id: 5,
    img: Files?.arrayImage.thrid,
    name: `Fully Customizable`,
    desc: `Set ut perspiciats unde omis iste natus erroe sit volupatem accusantium doloremque`,
    user: Files.BlogsUsersI?.BlogsUsersI3,
  },
];
export const HomeCarouseIArray = [
  {
    id: 1,
    img: Files?.HomeCarouseI.CarouselImg1,
    name: `Cutting Edge Technology`,
    desc: `Take the advantage of our cutting-edge solutions to increase your Return of Investment on IT.`,
  },
  {
    id: 2,
    img: Files?.HomeCarouseI.CarouselImg2,
    name: `Cross Device Compatibility`,
    desc: `Multi-device compatibility ensures that creating, viewing and providing quick and easy`,
  },
  {
    id: 3,
    img: Files?.HomeCarouseI.CarouselImg3,
    name: `Tailer Mode Development`,
    desc: `Scalable and dynamic systems with the ever-changing trends to meet your dynamic business needs`,
  },
  {
    id: 4,
    img: Files?.HomeCarouseI.CarouselImg1,
    name: `Cutting Edge Technology`,
    desc: `Take the advantage of our cutting-edge solutions to increase your Return of Investment on IT.`,
  },
  {
    id: 5,
    img: Files?.HomeCarouseI.CarouselImg2,
    name: `Cross Device Compatibility`,
    desc: `Multi-device compatibility ensures that creating, viewing and providing quick and easy`,
  },
];
export const whatwedoIArray = [
  {
    id: 1,
    img: Files?.whatwedoI.whatwedoI1,
    name: `Web Application`,
    desc: `Platform independant business solutions for maximum availability`,
    backgroundcolor: BaseColor.smokewhite,
  },
  {
    id: 2,
    img: Files?.whatwedoI.whatwedoI5,
    name: `Cross Platform Development`,
    desc: `Interactive Apps with perfect integration of frontend and backend`,
    backgroundcolor: BaseColor.yellowWhite,
  },
  {
    id: 3,
    img: Files?.whatwedoI.whatwedoI8,
    name: `SEO`,
    desc: `Let the world find you on top of others`,
    backgroundcolor: BaseColor.bluewhite,
  },
  {
    id: 4,
    img: Files?.whatwedoI.whatwedoI3,
    name: `Desktop Application`,
    desc: `Advanced autonomous technological softwares to make life simple`,
    backgroundcolor: BaseColor.pinkwhite,
  },
  {
    id: 5,
    img: Files?.whatwedoI.whatwedoI7,
    name: `BIG Data`,
    desc: `Get your decision making backed by inteligent insight`,
    backgroundcolor: BaseColor.orangewhite,
  },
  {
    id: 6,
    img: Files?.whatwedoI.whatwedoI4,
    name: `Cyber Security`,
    desc: `Make your digital assets secure and protected`,
    backgroundcolor: BaseColor.redwhite,
  },
  {
    id: 7,
    img: Files?.whatwedoI.whatwedoI2,
    name: `Mobile Applications`,
    desc: `The simplest but robust technology to accompany with you`,
    backgroundcolor: BaseColor.purplewhite,
  },
  {
    id: 8,
    img: Files?.whatwedoI.whatwedoI6,
    name: `Digital Marketing`,
    desc: `Business made easy in a digital world`,
    backgroundcolor: BaseColor.greenwhite,
  },
];

export const cardarray = [
  {
    id: 1,
    img: Files.avatarImage.first,
    desc: `Set ut perspiciats unde omis iste natus erroe sit volupatem accusantium doloremque`,
    name: `Huge Font libarary`,
    user: Files.BlogsUsersI?.BlogsUsersI1,
    color: BaseColor.secondory,
  },
  {
    id: 2,
    img: Files.avatarImage.seacond,
    desc: `Set ut perspiciats unde omis iste natus erroe sit volupatem accusantium doloremque`,
    name: `Easy Documentates.`,
    user: Files.BlogsUsersI?.BlogsUsersI2,
    color: BaseColor.skyBlue,
  },
  {
    id: 3,
    img: Files.avatarImage.thirad,
    desc: `Set ut perspiciats unde omis iste natus erroe sit volupatem accusantium doloremque`,
    name: `Fully Customizable`,
    user: Files.BlogsUsersI?.BlogsUsersI3,
    color: BaseColor.green,
  },
];
export const HowWeworkcardIs = [
  {
    id: 1,
    img: Files?.howweworkpng.firstImg,
    title: "I am the Title",
    dis: "In this example, weve created a Card component that accepts In this example, weve created a Card component that accepts",
    num: 1,
  },
  {
    id: 2,
    img: Files?.howweworkpng.secondImg,
    title: "I am the Title",
    dis: "In this example, weve created a Card component that accepts In this example, weve created a Card component that accepts",
    num: 2,
  },
  {
    id: 3,
    img: Files?.howweworkpng.thridImg,
    title: "I am the Title",
    dis: "In this example, weve created a Card component that accepts In this example, weve created a Card component that accepts",
    num: 1,
  },
];

export const reactarray = [
  {
    id: 1,
    img: Files.BlogsImages?.BlogsImage1,
    desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`,
    name: `Lorem Ipsum is simply dummy text of the printing.`,
    user: Files.BlogsUsersI?.BlogsUsersI1,
    color: BaseColor.secondory,
    icon: (
      <HiOutlineBuildingLibrary
        fontSize="large"
        style={{ color: BaseColor.white, fontSize: 30 }}
      />
    ),
    icon1: (
      <HiOutlineArrowNarrowRight
        fontSize="large"
        style={{ color: BaseColor.blackColor, fontSize: 36 }}
      />
    ),
  },
  {
    id: 2,
    img: Files.BlogsImages?.BlogsImage2,
    desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`,
    name: `Lorem Ipsum is simply dummy text of the printing.`,
    user: Files.BlogsUsersI?.BlogsUsersI2,
    color: BaseColor.skyBlue,
    icon: (
      <FaScrewdriverWrench
        fontSize="large"
        style={{ color: BaseColor.white, fontSize: 30 }}
      />
    ),
    icon1: (
      <HiOutlineArrowNarrowRight
        fontSize="large"
        style={{ color: BaseColor.blackColor, fontSize: 36 }}
      />
    ),
  },
  {
    id: 3,
    img: Files.BlogsImages?.BlogsImage3,
    desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. .`,
    name: `Lorem Ipsum is simply dummy text of the printing.`,
    user: Files.BlogsUsersI?.BlogsUsersI3,
    color: BaseColor.green,
    icon: (
      <FaAward
        fontSize="large"
        style={{ color: BaseColor.white, fontSize: 30 }}
      />
    ),
  },
];

export const reactarray1 = [
  {
    id: 1,
    img: Files.BlogsImages?.BlogsImage1,
    desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`,
    name: `Lorem Ipsum is simply dummy text of the printing.`,
    user: Files.BlogsUsersI?.BlogsUsersI1,
    color: BaseColor.secondory,
    icon: (
      <TbBellRinging2Filled
        fontSize="large"
        style={{ color: BaseColor.white, fontSize: 36 }}
      />
    ),
    icon1: (
      <HiOutlineArrowNarrowRight
        fontSize="large"
        style={{ color: BaseColor.blackColor, fontSize: 36 }}
      />
    ),
  },
  {
    id: 2,
    img: Files.BlogsImages?.BlogsImage2,
    desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`,
    name: `Lorem Ipsum is simply dummy text of the printing.`,
    user: Files.BlogsUsersI?.BlogsUsersI2,
    color: BaseColor.skyBlue,
    icon: (
      <MdComputer
        fontSize="large"
        style={{ color: BaseColor.white, fontSize: 30 }}
      />
    ),
    icon1: (
      <HiOutlineArrowNarrowRight
        fontSize="large"
        style={{ color: BaseColor.blackColor, fontSize: 36 }}
      />
    ),
  },
  {
    id: 3,
    img: Files.BlogsImages?.BlogsImage3,
    desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. .`,
    name: `Lorem Ipsum is simply dummy text of the printing.`,
    user: Files.BlogsUsersI?.BlogsUsersI3,
    color: BaseColor.green,
    icon: (
      <FaAward
        fontSize="large"
        style={{ color: BaseColor.white, fontSize: 30 }}
      />
    ),
  },
];
