import React from "react";
import {
  Dialog,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Color from "../../Config/Color";
import Lottie from "lottie-react";
import Files from "../../Config/Files";

const CustomModal = ({ open, handleClose, content }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      style={{
        position: "absolute",
        top: "10%",
        left: '5%',
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        style={{
          position: "absolute",
          right: "8px",
          top: "8px",
          color: Color.blackColor,
          zIndex: 1, // Ensure the close icon is above the content
        }}
      >
        <CloseIcon />
      </IconButton>

      <div style={{ width: "300px", height: "250px",}}>
        <Lottie
          animationData={Files?.lottie?.comingsoon}
          style={{ width: "100%", height: "100%" }}
          isClickToPauseDisabled={true}
          isPaused={true}
          isStopped={true}
          scrolling="false"
        />
      </div>
    </Dialog>
  );
};

export default CustomModal;
