import { React, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import useStyles from "./styles";
import BaseColor from "../../Config/Color";
// import CHeader from './App/Components/CHeader'
import { AiOutlinePlusCircle } from "react-icons/ai";
import { MdOutlineRemoveCircleOutline } from "react-icons/md";
import Files from "../../Config/Files";
import { FontFamily } from "../../Config/theme";
import CFooter from "../../Components/CFooter";
const faqs = [
  {
    id: 1,
    question: "What services does [Vihil infotech] offer?",
    answer:
      "[Vihil infotech] specializes in [list of services, e.g., web development, mobile app development, IT consulting, etc.]. We provide end-to-end solutions to meet your digital needs.",
  },
  {
    id: 2,
    question: "What technologies does your team specialize in?",
    answer:
      "Our team is proficient in a wide range of technologies, including but not limited to [list of technologies, e.g., ReactJS, React Native, Node.js, Python, etc.]. We stay updated with the latest advancements to deliver cutting-edge solutions.",
  },
  {
    id: 3,
    question: "How experienced is your team?",
    answer:
      "Our team comprises highly experienced professionals with a proven track record in the IT industry. We bring a wealth of knowledge and expertise to every project we undertake.",
  },
  {
    id: 4,
    question:
      "Can you handle both small projects and large-scale enterprise solutions?",
    answer:
      "Yes, [Vihil infotech] caters to a diverse clientele, ranging from startups to large enterprises. Our flexible approach allows us to tailor solutions to meet the unique requirements of each project.",
  },
  {
    id: 5,
    question: "What is your development process like?",
    answer:
      "We follow an agile development methodology, ensuring transparency, collaboration, and adaptability throughout the project lifecycle. Our process includes stages like consultation, design, development, testing, deployment, and ongoing support.",
  },
  {
    id: 6,
    question: "How do you ensure the security of the projects you undertake?",
    answer:
      "Security is a top priority for us. We implement industry best practices, conduct regular security audits, and follow stringent measures to safeguard your data and applications.",
  },
  {
    id: 7,
    question: "Do you provide ongoing support and maintenance?",
    answer:
      "Yes, we offer post-launch support and maintenance services to ensure that your applications run smoothly. Our team is available to address any issues and implement updates as needed.",
  },
  {
    id: 8,
    question:
      "What sets [Vihil infotech] apart from other IT service providers?",
    answer:
      "[Vihil infotech] stands out for its commitment to innovation, customer satisfaction, and the quality of our solutions. Our collaborative approach and focus on client success differentiate us in the industry.",
  },
  {
    id: 9,
    question: "How can I get a quote for my project?",
    answer:
      "To get a personalized quote, simply reach out to our team through [contact details]. We'll be happy to discuss your requirements and provide a tailored proposal.",
  },
  {
    id: 10,
    question:
      "Can you provide references or case studies of previous projects?",
    answer:
      "Certainly! We have a portfolio of successful projects and satisfied clients. Contact us, and we'll be happy to share relevant case studies and references.",
  },
];

const FAQ = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(null);
  // const [data, setData] = useState([]);
  // const navigate = useNavigate();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null); // Set to null to collapse if already expanded
  };

  // const fetchFaqs = async () => {
  //   const URL = BaseSetting.apiUrl + "faq";
  //   try {
  //     const response = await fetch(URL);
  //     const result = await response.json();
  //     setData(result?.results);
  //   } catch (error) {
  //     setData([]);
  //   }
  // };

  useEffect(() => {
    // Scroll to the top of the page
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // fetchFaqs();
  }, []);

  return (
    <div className={classes.mainContainer}>
      <Grid container>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />

        <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>

        {/* requently asked questions  title contaier */}
          <Grid container>
           

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div style={{ paddingTop: "120px" }}>
                <p className={classes.headerText}>Frequently asked questions</p>
                <p className={classes.headerDesText}>
                  Everything you need to know about the product and billing.
                </p>
              </div>

              {/* <div style={{ marginTop: 40 }}>
                {!_.isEmpty(data) &&
                  _.isArray(data) &&
                  data.map((item, index) => {
                    const panel = `panel${item.id}`;
                    return (
                      <Accordion
                        key={item.id}
                        expanded={expanded === panel}
                        onChange={handleChange(panel)}
                        style={{
                          borderBottom: "1px solid #e9e9e9",
                          boxShadow: "none",
                          background: "none",
                          padding: "0px",
                          margin: "0px",
                          borderRadius: 0,
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            expanded === panel ? (
                              <MdOutlineRemoveCircleOutline
                                color="blue"
                                size={20}
                              />
                            ) : (
                              <AiOutlinePlusCircle color="blue" size={20} />
                            )
                          }
                          aria-controls={`${panel}-content`}
                          id={`${panel}-header`}
                          style={{ border: 0 }}
                        >
                          <Typography
                            sx={{
                              width: "100%",
                              flexShrink: 0,
                              fontFamily: FontFamily.SemiBold,
                            }}
                          >
                            {item?.question}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography style={{ fontFamily: FontFamily.Light }}>
                            {item?.answer}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
              </div> */}
            </Grid>
          
          </Grid>


          {/* accordian of the FAQs */}
          <Grid container>
            <Grid item xs={1} sm={1} md={1} lg={2} xl={2} />
            <Grid item xs={10} sm={10} md={10} lg={8} xl={8}>
              <div style={{ marginTop: 40 }}>
                {faqs.map((item, index) => {
                  const panel = `panel${item.id}`;
                  return (
                    <Accordion
                      key={item.id}
                      expanded={expanded === panel}
                      onChange={handleChange(panel)}
                      style={{
                        borderBottom: "1px solid #E9E9E9",
                        boxShadow: "none",
                        background: "none",
                        padding: "20px",
                        margin: "0px",
                        borderRadius: 0,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          expanded === panel ? (
                            <MdOutlineRemoveCircleOutline
                              color={BaseColor.blue}
                              size={20}
                            />
                          ) : (
                            <AiOutlinePlusCircle color={BaseColor.blue} size={20} />
                          )
                        }
                        aria-controls={`${panel}-content`}
                        id={`${panel}-header`}
                        style={{ border: 0 }}
                      >
                        <Typography
                          sx={{
                            width: "100%",
                            flexShrink: 0,
                            fontFamily: FontFamily.SemiBold,
                          }}
                        >
                          {item?.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: 0 }}>
                        <Typography
                          style={{
                            fontFamily: FontFamily.Light,
                            paddingLeft: 15,
                            paddingTop: 0,
                          }}
                        >
                          {item?.answer}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </div>
              <Grid item xs={1} sm={1} md={1} lg={1.5} xl={1.5} />
            </Grid>
            <Grid container sx={{ paddingTop: 10 }}>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
              <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                <div
                  style={{
                    width: "100%",
                    background: BaseColor.backgroundCard,
                    borderRadius: 5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    paddingTop: "70px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                      width: "70%",
                    }}
                  >
                    <img
                      alt=""
                      src={Files?.images?.avtar1}
                      style={{ height: 50, width: 50, marginRight: -15 }}
                    />
                    <img
                      alt=""
                      src={Files?.images?.avtar2}
                      style={{ height: 70, width: 70 }}
                    />
                    <img
                      alt=""
                      src={Files?.images?.avtar3}
                      style={{ height: 50, width: 50, marginLeft: -15 }}
                    />
                  </div>
                  <span
                    style={{
                      fontFamily: FontFamily.ExtraBold,
                      fontSize: 18,
                      color: BaseColor.headigTextColor,
                      marginTop: 20,
                    }}
                  >
                    Still have questions?
                  </span>
                  <span
                    style={{
                      fontFamily: FontFamily.Light,
                      color: BaseColor.gray1,
                      marginTop: 10,
                      alignItems: "center",
                    }}
                  >
                    Can’t find the answer you’re looking for? Please chat to our
                    friendly team.
                  </span>
                  <div style={{ paddingBottom: 20 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        marginTop: 15,
                        background: BaseColor.blue
                      }}
                    >
                      {/* Get in Touch */}
                      <a
                        href="mailto:vihil3010@gmail.com"
                        style={{
                          textDecoration: "none",
                          color: BaseColor.white
                        }}
                      >
                        Get in Touch
                      </a>
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
        </Grid>
      </Grid>
      <div style={{ paddingTop: "80px" }}>
        <CFooter />
      </div>
    </div>
  );
};
export default FAQ;
