import { Grid } from "@mui/material";
import { React } from "react";
import { FontFamily } from "../../Config/theme";
import _ from "lodash";

const Vision = () => {
  // const [visionData, setvisionData] = useState([]);

  const visionData = [
    {
      id: 1,
      area: "Vision of our Company",
      description:
        "At Vihil InfoTech, we believe in a systematic approach for any project be it complex or simple. We are a group of individuals with a various set of skill set varies from Digital Marketing to IoT/Robotics solutions. We have our dedicated team for your project which uses various methods such as agile Scrum & agile Kanban. We ensure top-notch quality, on-time delivery, and agility for your project.",
    },
    // Add more objects as needed
  ];

  // useEffect(() => {
  //   fetchDataVision();
  // }, []);

  // const fetchDataVision = async () => {
  //   const URL = BaseSetting.apiUrl;

  //   try {
  //     const response = await axios.get(
  //       URL + "common?page=1&limit=50&placeOfContent=vision"
  //     );
  //     console.log("responce", response);
  //     const data = response.data;
  //     if (!_.isEmpty(data?.results) && _.isArray(data?.results)) {
  //       setvisionData(data?.results);
  //     } else {
  //       setvisionData([]);
  //     }
  //   } catch (error) {
  //     setvisionData([]);
  //   }
  // };

  return (
    <div>
      {!_.isEmpty(visionData) &&
        _.isArray(visionData) &&
        visionData.map((item, index) => (
          <Grid container sx={{ margin: "20px 0px" }} key={index}>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <h1
                style={{
                  textAlign: "center",
                  fontFamily: FontFamily.Medium,
                }}
              >
                {item?.area}
              </h1>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p
                  style={{
                    width: "85%",
                    display: "flex",
                    justifyContent: "center",
                    fontFamily: FontFamily.Regular,
                    textAlign: "justify",
                  }}
                >
                  {item?.description}
                </p>
              </div>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>
          </Grid>
        ))}
    </div>
  );
};

export default Vision;
