import { makeStyles } from "@mui/styles";
import BaseColor from "../../Config/Color";
import { useMediaQuery } from "@mui/material";
import theme, { FontFamily } from "../../Config/theme";

const useStyles = makeStyles(() => {
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const isCustomWidth = useMediaQuery(
    "(min-width: 700px) and (max-width: 800px)"
  );

  return {
    inputsBackground: {
      background: BaseColor.primary,
      width: "100%",
      height: isCustomWidth ? "75vh" : "90vh",
      position: "relative",
    },
    contactUsBtn: {
      width: isMatch ? 140 : 180,
      // height: 40,
      borderRadius: 8,
      border: `1px solid ${BaseColor.white}`,
      background: "rgba(255, 255, 255, 0.44)",
      backdropFilter: "blur(5.5px)",
      display: "flex",
      alignItems: "center",
      padding: "10px 15px 10px 15px",
      justifyContent: "space-between",
      cursor: "pointer",
      "&:hover": {
        boxShadow: "0 0 10px rgba(255, 255, 255, 0.5)",
      },
      [theme.breakpoints.between("1360", "1400")]: {
        width: 180,
      },
    },
    conatctUsText: {
      fontSize: 16,
      fontWeight: "700",
      color: BaseColor.white,
      fontFamily: FontFamily.Regular,
      [theme.breakpoints.between("1360", "1400")]: {},
      textDecoration: "none",
    },
    arrowStyle: {
      height: 30,
      width: 30,
      borderRadius: 3,
      background: BaseColor.white,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      transition: "transform 0.3s",
      "&:hover": {
        boxShadow: "0 0 10px rgba(255, 255, 255, 0.5)",
      },
    },
    arrow: {
      color: BaseColor.color,
      "&:hover": {
        transform: "translateX(5px)",
      },
    },
    boxMainContainer: {
      background: BaseColor.darkGray,
      borderRadius: 10,
      backdropFilter: "blur(3px)",
      width: 220,

      padding: 15,

      left: "150px",
      top: "250px",
      position: "absolute",

      [theme.breakpoints.down("md")]: {
        width: 210, // Adjust height for screens with width less than or equal to the "sm" breakpoint
        top: "60%",
        left: "1%",
      },
      [theme.breakpoints.between("1360", "1400")]: {
        width: 180, // Adjust height for screens with width less than or equal to the "sm" breakpoint
        top: "25%",
        padding: 5,
        left: "5%",
      },
    },
    userImg: {
      height: 50,
      width: 50,
      borderRadius: 50,
      [theme.breakpoints.down("md")]: {
        width: 30, // Adjust height for screens with width less than or equal to the "sm" breakpoint
        height: 30,
        borderRadius: 30,
      },
    },
    popularTechBox: {
      width: 230,
      height: 230,
      background: BaseColor.whiteBlur,
      backdropFilter: "blur(8px)",
      borderRadius: 10,
      position: "absolute",
      right: "10%",
      padding: 10,
      bottom: "9%",
      [theme.breakpoints.down("md")]: {
        bottom: "-50%", // Adjust height for screens with width less than or equal to the "sm" breakpoint
        width: 200,
        height: 200,
      },
      [theme.breakpoints.between("1360", "1400")]: {
        width: 180,
        height: 180,
        bottom: "10%",
      },
    },
    mainContainer: {
      background: BaseColor.white,
      borderRadius: 12,
      height: 68,
      padding: 10,
      width: 240,
      alignItems: "center",
      position: "absolute",
      right: "12%",
      bottom: "28%",

      [theme.breakpoints.down("md")]: {
        bottom: "-20%", // Adjust height for screens with width less than or equal to the "sm" breakpoint
        right: "13%",
        width: 220,
        height: 55,
      },
      [theme.breakpoints.between("1360", "1400")]: {
        width: 200,
        height: 52,
        bottom: "32%",
      },
    },

    circle: {
      height: 40,
      width: 40,
      borderRadius: 30,
      background: BaseColor.darkPink,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      bottom: -8,
      [theme.breakpoints.down("md")]: {
        height: 40,
        width: 40,
      },
    },
    circle2: {
      height: 40,
      width: 40,
      borderRadius: 30,
      background: BaseColor.darkpurper,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      bottom: -8,
      [theme.breakpoints.down("md")]: {
        height: 40,
        width: 40,
      },
    },
    subContainer: {
      display: "flex",
      flexDirection: "row",
    },
    mainContainer2: {
      background: BaseColor.white,
      borderRadius: 12,
      height: 68,
      padding: 10,
      width: 300,
      alignItems: "center",
      position: "absolute",
      right: "7.8%",
      bottom: "12%",
      [theme.breakpoints.down("md")]: {
        bottom: "-45%", // Adjust height for screens with width less than or equal to the "sm" breakpoint
        width: 280,
        right: "5%",
        height: 55,
      },
      [theme.breakpoints.between("1360", "1400")]: {
        // width: 200,
        // height: 50,
        bottom: "13.5%",
        width: 280,
        height: 52,
      },
    },

    maintext: {
      marginLeft: 10,
      marginTop: 2,
      fontFamily: FontFamily.Medium,
      fontSize: 16,
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
      },
      [theme.breakpoints.between("1360", "1400")]: {
        fontSize: 12,
      },
    },
    anothertext: {
      padding: "8px",
      backgroundColor: BaseColor.bggrey,
      color: BaseColor.lightgrey,
      fontSize: 16,
      margin: "8px",
      borderRadius: 6,
      fontFamily: FontFamily.Regular,
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
      },
      [theme.breakpoints.between("1360", "1400")]: {
        fontSize: 12,
      },
    },
    anothertext1: {
      padding: "8px",
      backgroundColor: BaseColor.bgpink,
      color: BaseColor.carret,
      fontSize: 16,
      margin: "8px",
      borderRadius: 6,
      fontFamily: FontFamily.Regular,
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
      },
      [theme.breakpoints.between("1360", "1400")]: {
        fontSize: 14,
      },
    },
    anothertext3: {
      padding: "8px",
      backgroundColor: BaseColor.bgpurple,
      color: BaseColor.darkpurper,
      marginRight: "5px",
      fontSize: 16,
      margin: "8px",
      borderRadius: 6,

      fontFamily: FontFamily.Regular,
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
      },
      [theme.breakpoints.between("1360", "1400")]: {
        fontSize: 14,
      },
    },
    anothertext4: {
      padding: "8px",
      backgroundColor: BaseColor.bgyellow,
      color: BaseColor.yellow,
      fontSize: 16,
      margin: "8px",
      borderRadius: 6,
      fontFamily: FontFamily.Regular,
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
      },
      [theme.breakpoints.between("1360", "1400")]: {
        fontSize: 14,
      },
    },
    phoneimg: {
      position: "absolute",
      right: "15%",
      height: "80px",
      bottom: "95px",
      [theme.breakpoints.down("md")]: {
        height: "80px",
        bottom: "13%",
        right: "22%",
      },
    },
    phoneshadow: {
      position: "absolute",
      right: "6%",
      bottom: "-1.5%",
      width: "20%",
      [theme.breakpoints.down("md")]: {
        bottom: "-1%",
        right: "1%",
        left: "55%",
        width: "40%",
        height: "45%",
      },
    },
    vector1: {
      position: "absolute",
      bottom: "90px",
      height: isMatch ? "180px" : "200px",
      right: "130px",
      [theme.breakpoints.down("md")]: {
        bottom: "100px",
        right: "100px",
      },
      [theme.breakpoints.between("1360", "1400")]: {
        height: "120px",
        bottom: "62px",
        right: "103px",
      },
    },
    // customRating: {
    //   "& .MuiRating-icon": {
    //     width: "100px", // Set your desired width here
    //   },
    // },
    username: {
      color: BaseColor.white,
      fontSize: 20,
      fontWeight: "700",
      marginLeft: 20,
      fontFamily: FontFamily.Medium,
      [theme.breakpoints.down("md")]: {
        fontSize: 16,
      },
      [theme.breakpoints.between("1360", "1400")]: {
        fontSize: 16,
      },
    },
    textuser: {
      color: BaseColor.white,
      fontSize: 16,
      fontWeight: "600",
      paddingLeft: 10,
      fontFamily: FontFamily.Medium,
      [theme.breakpoints.down("md")]: {
        fontSize: 14,
      },
      [theme.breakpoints.between("1360", "1400")]: {
        fontSize: 12,
      },
    },
    computer: {
      position: "absolute",
      top: isMatch ? "12%" : "60px",
      left: isMatch ? "10px" : "40px",
      width: "7%",
      [theme.breakpoints.down("md")]: {
        width: "12%",
      },
      [theme.breakpoints.between("1360", "1400")]: {
        top: "5%",
        left: "20px",
      },
    },
    eplisse: {
      position: "absolute",
      top: isMatch ? "2%" : "-11px",
      left: isMatch ? "none" : "-25px",
      width: "15%",
      [theme.breakpoints.down("md")]: {
        width: "35%",
        left: "-10%",
      },
      [theme.breakpoints.between("1360", "1400")]: {
        top: "-5%",
        left: "-30px",
      },
    },
    bulb: {
      position: "absolute",
      top: isMatch ? "35px" : "80px",
      right: isMatch ? "50px" : "120px",
      width: "10%",
      [theme.breakpoints.down("md")]: {
        width: "18%",
      },

      [theme.breakpoints.between("1360", "1400")]: {
        top: "10px",
      },
    },
    textwrap: {
      color: BaseColor.textGrey,
      fontSize: 12,
      fontWeight: "500",
      fontFamily: FontFamily.Medium,
      paddingLeft: 10,

      //     [theme.breakpoints.down('md')]: {
      //       overflow: 'hidden',
      //       whiteSpace: 'nowrap',
      //       textOverflow: 'ellipsis',
      //       lineHeight: '2.4em', // Adjust line height based on your font size
      // maxHeight: '2.8em',

      //        }
    },
    headphone: {
      position: "absolute",
      left: isMatch
        ? "8%"
        : //  isLargeScreen ? "12%"
          "253px",
      bottom: isMatch
        ? "-90%"
        : //  isLargeScreen ? "-40%" :
          "",
      width: "8%",
      //  width : isLargeScreen ? "10%" : "",
      [theme.breakpoints.down("md")]: {
        width: "15%",
        bottom: "-5%",
      },
      [theme.breakpoints.between("1360", "1400")]: {
        bottom: "-10%",
      },
    },
    eplisse1: {
      position: "absolute",
      left: isMatch ? "2%" : "170px",
      bottom: isMatch
        ? "-100%"
        : // isLargeScreen ? "-53%" :
          "-196px",
      //  width : isLargeScreen ? "20%" : "",
      [theme.breakpoints.down("md")]: {
        width: "40%",
        bottom: "-25%",
        left: "-5%",
      },
      [theme.breakpoints.between("1360", "1400")]: {
        bottom: "-30%",
        left: "2.5%",
      },
    },
  };
});
export default useStyles;
