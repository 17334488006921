const actions = {
  SET_COMMON_DATA: "Common/SET_COMMON_DATA",

  setCommonData: (data) => (dispatch) => {
    dispatch({
      type: actions.SET_COMMON_DATA,
      commonData: data,
    });
  },
};
export default actions;
