import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography'
// import {customTheme} from '../../utils/baseStyles';
import BaseColor from '../../Config/Color';
import { FontFamily } from '../../Config/theme';



const CCard = props => {
  const {
    normalCard = false,
    icon,
    title,
    numberCard = false,
    content,
    dis,
    color
    ,
  } = props;
  const cardStyle = {
    color: color, // Set the background color based on the color property
    
  };
  return (
    <>

    {normalCard ? (
      
      <Card
      sx={{
        borderRadius: '10px',
        width: '90%',
        transition: 'transform 0.3s', // Add a transition for a smooth effect
        '&:hover': {
          transform: 'scale(1.05)', // Scale up the card on hover
       },
      }}
    > <CardContent>
        <div>
        <Typography style={{color:color ,fontSize:32,fontFamily:FontFamily.SemiBold }}>
          {title}
        </Typography>
        </div>
        
        <div style={{flexDirection:'row' }}>
        <Typography style={cardStyle}>
           --------------
           {icon}
           {/* <ArrowCircleRightOutlinedIcon fontSize="large" style={{justifyContent:'center' ,position:'relative' ,top:10}} /> */}
        </Typography>
        </div>
        <Typography style={{fontSize:20 ,paddingTop:'20px' ,fontFamily:FontFamily.SemiBold}}>
          {content}
        </Typography>
        <Typography style={{color:BaseColor.black ,fontSize:16 ,paddingTop:'20px' }}>
          {dis}
        </Typography>
      </CardContent>
    </Card>) : null}

    {
      numberCard ? (
        <Card sx={{ borderRadius:"10px" ,width:'90%' }}>
        <CardContent>
          <div>
          <Typography style={{color:BaseColor.blueBorder ,fontSize:32,fontFamily:FontFamily.SemiBold }}>
            {title}
          </Typography>
          </div>
          
          
          <Typography style={{fontSize:20 ,paddingTop:'20px' ,fontFamily:FontFamily.SemiBold}}>
            {content}
          </Typography>
          <Typography style={{color:BaseColor.black ,fontSize:16 ,paddingTop:'20px' }}>
            {dis}
          </Typography>
        </CardContent>
      </Card> ) : null
    }
       {/* <Card sx={{ borderRadius:"10px" ,width:'90%' }}>
      <CardContent>
        <div>
        <Typography style={{color:color ,fontSize:32,fontFamily:FontFamily.SemiBold }}>
          {title}
        </Typography>
        </div>
        
        <div style={{flexDirection:'row' }}>
        <Typography style={cardStyle}>
           ------------------------- 
           {icon}
           <ArrowCircleRightOutlinedIcon fontSize="large" style={{justifyContent:'center' ,position:'relative' ,top:10}} />
        </Typography>
        </div>
        <Typography style={{fontSize:20 ,paddingTop:'20px' ,fontFamily:FontFamily.SemiBold}}>
          {content}
        </Typography>
        <Typography style={{color:BaseColor.black ,fontSize:16 ,paddingTop:'20px'  ,paddingTop:20}}>
          {dis}
        </Typography>
      </CardContent>
    </Card> */}
    </>
  );
};
export default CCard;
