/* eslint-disable jsx-a11y/alt-text */
import { React, useEffect } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import BaseColor from "../../Config/Color";
import { ServicesArray,chatboardtechnologyiconArray,crosstechnologyiconArray,dashbaordtechnologyiconArray,pwatechnologyiconArray,technologyiconArray, webtechnologyiconArray } from "../../Config/staticData";
import CSubHeader from "../../Components/CSubHeader";
import { FontFamily } from "../../Config/theme";
// import CHeader from './App/Components/CHeader'
import CFooter from "../../Components/CFooter";

import { motion } from "framer-motion";
const OurServices = () => {
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    // Scroll to the top of the page
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <div
        style={{
          // height: '100vh',

          background: BaseColor.white,
          paddingTop: "100px",
        }}
      >
        {/* <div>
        <CHeader />
      </div> */}
        {/* <CSubHeader header1='Our' header2='Services' style={{fontSize:25 ,fontFamily:FontFamily.ExtraBold}} /> */}
        <Grid container> 
          <Grid item xs={0.5} sm={0.5} md={1} lg={1} xl={1} />  
          <Grid item
            xs={11}
            sm={11}
            md={10}
            lg={10}
            xl={10}
            style={{
              color: BaseColor.blackColor,
            }}
          >
            <CSubHeader
              header1="Our"
              header2="Capabilities"
              style={{ fontSize: 30, fontFamily: FontFamily.ExtraBold }}
            />
            <div>
              {ServicesArray.map((item, index) => {
                return (
                  <Grid 
                    container
                    style={{
                      display: "flex",
                      flexDirection: item?.id % 2 === 0 ? "row-reverse" : "row",
                      paddingTop: "20px",
                    }}
                    key={item.id}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={5.5}
                      xl={5.5}
                      key={item.id}
                      style={{
                      display: "flex",
                      }}
                    >
                      <motion.div style={{}} whileHover={{ scale: 1.1 }}>
                        <img
                          src={item?.img}
                          style={{
                            height: "100%",
                            width: isMatch ? "92vw" : "100%",
                            objectFit: "contain",
                          }}
                          // // borderRadius: 12,
                          // boxShadow:
                          //   '0px 0px 6px 0px rgba(196, 196, 196, 0.80)'
                        />
                      </motion.div>
                    </Grid>
                    <Grid item lg={1} xl={1}></Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={5.5}
                      xl={5.5}
                      style={{
                        display: "flex",
                      }}
                    >
                      <div>
                        <div style={{}}>
                          <span
                            style={{
                              fontFamily: FontFamily.Bold,
                              fontSize: 20,
                              lineHeight: "210%",
                            }}
                          >
                            {item?.title}
                          </span>
                        </div>
                        <div style={{ padding: "5px 0px" }}>
                          <span
                            style={{
                              fontFamily: FontFamily.Regular,
                              lineHeight: "210%",
                              fontSize: 16,
                              textAlign: "justify",
                            }}
                          >
                            {item?.desc1}
                          </span>
                        </div>
                        <br />
                        <div>
                          <span
                            style={{
                              fontFamily: FontFamily.Regular,
                              lineHeight: "210%",
                              fontSize: 16,
                            }}
                          >
                            {item?.desc2}
                          </span>
                        </div>
                        <div>
                          {item.id === 3  && item.id === 4 ? null :
                          <div>
                          <span style={{fontSize:18 , fontFamily:FontFamily.Bold}}>
                            Technologies
                            </span>
                            </div>}
                          <div style={{display:'flex' ,flexDirection:'row',flexWrap:'wrap' }}>
                          
                            { item.id === 1 && (
                              technologyiconArray.map((item)=>{
                                return(
                                  <div key={item.id}>
                                  
                                  <div style={{display:'flex' ,flexDirection:'row'}}>
                                   <img src={item.img}  style={{height:item.hight ,width:item.width , margin:10}}/>
                                    </div>
                                    </div>
                                )
                              })
              )}
                { item.id === 2 && (
                              webtechnologyiconArray.map((item)=>{
                                return(
                                  <div key={item.id}>
                                  
                                  <div style={{display:'flex' ,flexDirection:'row'}}>
                                   <img src={item.img}  style={{height:item.hight ,width:item.width , margin:10}}/>
                                    </div>
                                    </div>
                                )
                              })
              )}
                { item.id === 3 && (
                              crosstechnologyiconArray.map((item)=>{
                                return(
                                  <div key={item.id}>
                                  
                                  <div style={{display:'flex' ,flexDirection:'row'}}>
                                   <img src={item.img}  style={{height:item.hight ,width:item.width , margin:10}}/>
                                    </div>
                                    </div>
                                )
                              })
              )}
                { item.id === 4 && (
                              pwatechnologyiconArray.map((item)=>{
                                return(
                                  <div key={item.id}>
                                  
                                  <div style={{display:'flex' ,flexDirection:'row'}}>
                                   <img src={item.img}  style={{height:item.hight ,width:item.width , margin:10}}/>
                                    </div>
                                    </div>
                                )
                              })
              )}
               { item.id === 5 && (
                              dashbaordtechnologyiconArray.map((item)=>{
                                return(
                                  <div key={item.id}>
                                  
                                  <div style={{display:'flex' ,flexDirection:'row'}}>
                                   <img src={item.img}  style={{height:item.hight ,width:item.width , margin:10}}/>
                                    </div>
                                    </div>
                                )
                              })
              )}
              { item.id === 6 && (
                              chatboardtechnologyiconArray.map((item)=>{
                                return(
                                  <div key={item.id}>
                                  
                                  <div style={{display:'flex' ,flexDirection:'row'}}>
                                   <img src={item.img}  style={{height:item.hight ,width:item.width , margin:10}}/>
                                    </div>
                                    </div>
                                )
                              })
              )}
                            </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                );
              })}
            </div>
          </Grid>
          <Grid item xs={0.5} sm={0.5} md={1} lg={1} xl={1} />
        </Grid>

        {/* <Stayinhteloop /> */}

        <div>
          <CFooter />
        </div>
      </div>
    </>
  );
};
export default OurServices;
