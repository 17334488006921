import { Suspense } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./App/Pages/Home";
import Login from "./App/Pages/Login";
import OurServices from "./App/Pages/OurServices";
import ContactUs from "./App/Pages/ContactUs/ContactUs";
import CHeader from "./App/Components/CHeader";
import WhoWeAre from "./App/Pages/WhoWeAre";
// import FAQ from './App/Pages/FAQ'
import Blog from "./App/Pages/Blog";
import HowWeWork from "./App/Pages/HowWeWork";
import FAQ from "./App/Pages/FAQ";

function App() {
  return (
    <>
      {/* <div
      style={{
        height: '100vh',
        background: BaseColor.whiteColor
      }}
    >   */}
      <CHeader />
      <Suspense>
        <Routes>
          <Route path={"/"} element={<Home />} />
          <Route path={"/login"} element={<Login />} />
          <Route path={"/our-services"} element={<OurServices />} />
          <Route path={"/who-we-are"} element={<WhoWeAre />} />
          <Route path={'/ContactUs'} element={<ContactUs />} />
          <Route path={"/FAQ"} element={<FAQ />} />
          {/* <Route path={"/TermsAndCondition"} element={<TermAndCondition />} /> */}
          <Route path={"/blog"} element={<Blog />} />
          <Route path={"/home"} element={<Home />} />
          {/* <Route path={'/privacy-policy'} element={<PrivacyPolicy />} /> */}
          <Route path={"/how-we-work"} element={<HowWeWork />} />
        </Routes>
      </Suspense>
      {/* </div> */}
    </>
  );
}

export default App;
